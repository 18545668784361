import {apiAddress} from "./APILink";
import {getProductInfoAsync} from "./ProductInfo";

const addToCart = async (product_id, amount, session_id = null) => {
    if (session_id != null) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                product_id, session_id, amount
            }),
        };
        const response = await fetch(apiAddress + 'api/scripts/Cart.php', requestOptions);
        return await response.json();
    } else {
        let cartIDs = {};
        if (localStorage.getItem('cart')) {
            cartIDs = JSON.parse(localStorage.getItem('cart'));
        }
        let amountStored = cartIDs[product_id] ?? 0;
        amountStored += +amount;
        if (amountStored >= 1) {
            cartIDs[product_id] = amountStored;
            localStorage.setItem('cart', JSON.stringify(cartIDs));
        }
    }
}

const deleteFromCart = async (product_id, session_id = null) => {
    if (session_id !== null) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                product_id, session_id
            }),
        };
        const response = await fetch(apiAddress + 'api/scripts/Cart.php?delete', requestOptions);
        return await response.json();
    } else {
        let cartIDs = {};
        if (localStorage.getItem('cart')) {
            cartIDs = JSON.parse(localStorage.getItem('cart'));
        }
        delete cartIDs[product_id];
        localStorage.setItem('cart', JSON.stringify(cartIDs));
    }
}

const emptyLocalCart = () => {
    localStorage.removeItem('cart');
}

const fetchCart = async (cartCtx, loginCtx) => {
    if (loginCtx.isLoggedIn() || localStorage.getItem('loggedIn')) {
        const session_id = loginCtx.getSessionId();
        cartCtx.clearCart();
        let fetchAddress = apiAddress + 'api/scripts/Cart.php?session_id=' + session_id;
        if (localStorage.getItem('cart')) {
            fetchAddress = apiAddress + 'api/scripts/Cart.php?session_id=' + session_id + '&cart=' + localStorage.getItem('cart');
        }
        fetch(fetchAddress, {method: 'get'})
            .then(r => r.json())
            .then(cartData => {
                if (cartData.error === 'ok') {
                    cartData.items.forEach(item => {
                        cartCtx.addItem(item);
                    });
                }
            });
    } else {
        let cartIDs = {};
        if(localStorage.getItem('cart')){
            cartIDs = JSON.parse(localStorage.getItem('cart'));
        }
        for (let id in cartIDs) {
            getProductInfoAsync(id)
                .then((item) => {
                    if (item.id !== undefined) {
                        const cartItem = {
                            id: item.id,
                            name_ru: item.name_ru,
                            name_ro: item.name_ro,
                            subcategory_id: item.subcategory_id,
                            price: item.price,
                            discount: item.discount,
                            amount: cartIDs[id],
                            image: item.main_image
                        };
                        cartCtx.addItem(cartItem);
                    } else {
                        deleteFromCart(id);
                    }
                });
        }
    }
}

export {addToCart, fetchCart, deleteFromCart, emptyLocalCart};