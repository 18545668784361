import {formatPhone} from "./Processors";

const isEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

const isPhone = (phone) => {
    phone = formatPhone(phone);
    return phone.length === 12;
}

const isValidPassword = (password) => {
    return password.length >= 8;
};

export {isEmail, isPhone, isValidPassword};