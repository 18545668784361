import {apiAddress} from "./APILink";

const getCategories = (dispatch) => {
    dispatch({type: 'SetLoadingCategory'});
    fetch(apiAddress + 'api/scripts/Category.php', {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadCategories', payload: data});
        });
};

export {getCategories};