import {Fragment, useEffect, useState} from "react";

import Col from "../../components/grid/Col";
import SlideDown from "react-slidedown/lib/slidedown";
import CategorySlider from "./CategorySlider";
import Line from "../../components/UI/Line";
import TriangleClosed from "../../assets/icons/TriangleClosed";
import TriangleOpened from "../../assets/icons/TriangleOpened";

import './CategoryOption.css';

const CategoryOption = (props) => {
    const [opened, setOpened] = useState(props.active === props.id);

    const chooseOptionHandler = () => {
        props.onClick(props.id);
        setOpened(prevState => !prevState);
    }

    useEffect(() => {
        setOpened(props.active === props.id);
    }, [props.active, props.id]);

    return (
        <Fragment>
            <Col col={3} className='category-option__card'>
                <article className='category-option' style={{backgroundImage: `url(${props.img})`}} onClick={chooseOptionHandler}>
                    <div className='category-option__shadow d-flex align-items-end desktop-only'>
                        <h1 className='category-option__title primary-text'>
                            {props.title}
                        </h1>
                    </div>
                    <div className='tablet-only w-100 d-flex'>
                        <div className='category-option__image'>
                            <img src={props.imgMob} alt='' className='w-100' />
                        </div>
                        <div className='category-option__info d-flex align-items-center justify-content-between'>
                            <h1 className='category-option__title primary-text'>
                                {props.title}
                            </h1>
                            <span className='category-option__controls'>
                                { opened ? <TriangleOpened /> :  <TriangleClosed /> }
                            </span>
                        </div>
                    </div>
                </article>
            </Col>
            <SlideDown className={'category-option__slide-down'}>
                { opened ? <CategorySlider subcategories={props.subcategories} /> : null }
            </SlideDown>
            <div className='tablet-only w-100'>
                <Line dark />
            </div>
        </Fragment>
    );
};

export default CategoryOption;