import {Fragment, useContext, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import LoginContext from "../../../store/login-context";
import {useTranslation} from "react-i18next";
import CartContext from "../../../store/cart-context";

import Container from "../../container/Container";
import Row from "../../grid/Row";
import CartWidget from "./CartWidget";
import BurgerMenu from "./BurgerMenu";

import './Header.css';
import PhoneIcon from "../../../assets/icons/PhoneIcon";
import BurgerButtonIcon from "../../../assets/icons/BurgerButtonIcon";
import AvatarIcon from "../../../assets/icons/AvatarIcon";
import Logo from "../../../assets/icons/Logo";
import CartIcon from "../../../assets/icons/CartIcon";

const Header = () => {
    const loginCtx = useContext(LoginContext);
    const cartCtx = useContext(CartContext);
    const { t, i18n } = useTranslation();

    const [openedBurgerMenu, setOpenedBurgerMenu] = useState(false);

    const openBurgerMenuHandler = () => setOpenedBurgerMenu(prevState => !prevState);

    const avatarIconLink = loginCtx.isLoggedIn() ? '/profile' : '/login';

    const toggleLanguage = () => {
        if (i18n.resolvedLanguage === 'ru') {
            i18n.changeLanguage('ro');
        } else {
            i18n.changeLanguage('ru');
        }
        window.location.reload();
    }

    return (
        <Fragment>
            <header>
                <Container>
                    <Row className='justify-content-between align-items-center' style={{flexWrap: 'nowrap'}}>
                        <div className='d-flex align-items-center until-lg'>
                            <button className='header__button' onClick={openBurgerMenuHandler}><BurgerButtonIcon /></button>
                            <Link to={avatarIconLink} className='header__button'><AvatarIcon /></Link>
                        </div>
                        <Link to='/' className='header__logo-container'><Logo className='header__logo' /></Link>
                        <div className='d-flex align-items-center from-lg'>
                            <nav className='header__links'>
                                <NavLink to='/' className='header__link'>{t('navigation.home')}</NavLink>
                                <NavLink to='/shop' className='header__link'>{t('navigation.shop')}</NavLink>
                                <NavLink to='/contacts' className='header__link'>{t('navigation.contacts')}</NavLink>
                                <NavLink to='/info' className='header__link'>{t('navigation.info')}</NavLink>
                            </nav>
                            {
                                loginCtx.isLoggedIn() ? <Link to='/profile' className='header__login'>{t('navigation.profile')}</Link>
                                    : <Link to='/login' className='header__login'>{t('navigation.login')}</Link>
                            }
                            <button className='header__link-button primary-text' onClick={toggleLanguage}>
                                {i18n.resolvedLanguage === 'ru' ? 'RO' : 'RU'}
                            </button>
                            <button className='header__link-button header__link-cart primary-text' onClick={cartCtx.toggleCart}><CartIcon /></button>
                            <a href='tel:+37379556644' className='header__link-button secondary-text'><PhoneIcon /></a>
                        </div>
                        <div className='d-flex align-items-center until-lg'>
                            <button className='header__button header__link-cart' onClick={cartCtx.toggleCart}><CartIcon /></button>
                            <a href='tel:+37379556644' className='header__button header__link-cart secondary-text'><PhoneIcon style={{width: '16px'}} /></a>
                        </div>
                    </Row>
                </Container>
            </header>
            <CSSTransition in={openedBurgerMenu} timeout={300} classNames='burger-menu' mountOnEnter unmountOnExit>
                <BurgerMenu onClose={openBurgerMenuHandler} lngChange={toggleLanguage} lng={i18n.resolvedLanguage} />
            </CSSTransition>
            <CSSTransition in={cartCtx.isOpened} timeout={300} classNames='cart-widget' mountOnEnter unmountOnExit>
                <CartWidget onClose={cartCtx.closeCart} />
            </CSSTransition>
            <div style={{marginTop: '120px'}} />
        </Fragment>
    );
};

export default Header;