import {memo} from "react";

import './Ball.css';

const Ball = (props) => {
    const ballStyle = {
        background: props.background,
        top: props.top,
        bottom: props.bottom,
        left: props.left,
        right: props.right
    };

    return (
        <div className='ball' style={ballStyle} />
    );
};

export default memo(Ball);