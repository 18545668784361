import { useReducer } from "react";

import CartContext from "./cart-context";

const defaultCartState = {
    items: [],
    totalAmount: 0,
    isOpened: false
};

const cartReducer = (state, action) => {
    if (action.type === 'ADD') {
        const price = action.item.discount !== null ? action.item.discount : action.item.price;
        let updatedTotalAmount = (+state.totalAmount + +price * +action.item.amount).toFixed(2);

        const existingCartItemIndex = state.items.findIndex(
            item => item.id === action.item.id
        );
        const existingCartItem = state.items[existingCartItemIndex];
        let updatedItems;

        if (existingCartItem) {
            let validTransaction = true;
            if (existingCartItem.amount <= 1 && action.item.amount < 0) {
                updatedTotalAmount = state.totalAmount;
                updatedItems = [...state.items];
                validTransaction = false;
            }
            if (validTransaction) {
                const updatedItem = {
                    ...existingCartItem,
                    amount: +existingCartItem.amount + +action.item.amount
                };
                updatedItems = [...state.items];
                updatedItems[existingCartItemIndex] = updatedItem;
            }
        } else {
            updatedItems = state.items.concat(action.item);
        }
        return {
            ...state,
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };
    }

    if (action.type === 'INCREASE') {
        const existingCartItemIndex = state.items.findIndex(
            item => item.id === action.id
        );
        const existingCartItem = state.items[existingCartItemIndex];
        const price = existingCartItem.discount ? existingCartItem.discount : existingCartItem.price;
        const updatedTotalAmount = (+state.totalAmount + +price).toFixed(2);

        const updatedItem = {
            ...existingCartItem,
            amount: +existingCartItem.amount + 1
        };
        const updatedItems = [...state.items];
        updatedItems[existingCartItemIndex] = updatedItem;

        return {
            ...state,
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };
    }

    if (action.type === 'DECREASE') {
        const existingCartItemIndex = state.items.findIndex(
            item => item.id === action.id
        );
        const existingCartItem = state.items[existingCartItemIndex];
        const price = existingCartItem.discount ? existingCartItem.discount : existingCartItem.price;
        const updatedTotalAmount = (+state.totalAmount - +price).toFixed(2);
        let updatedItems;

        if (+existingCartItem.amount === 1) {
            updatedItems = state.items.filter(item => {
                return item.id !== action.id
            });
        } else {
            const updatedItem = {
                ...existingCartItem,
                amount: +existingCartItem.amount - 1
            };
            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        }
        return {
            ...state,
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };
    }

    if (action.type === 'DELETE') {
        const existingCartItemIndex = state.items.findIndex(
            item => item.id === action.id
        );
        const existingCartItem = state.items[existingCartItemIndex];
        const price = existingCartItem.discount ? existingCartItem.discount : existingCartItem.price;
        const updatedTotalAmount = (+state.totalAmount - +price * existingCartItem.amount).toFixed(2);
        const updatedItems = state.items.filter(item => {
            return item.id !== action.id
        });

        return {
            ...state,
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };
    }

    if (action.type === 'CLEAR') {
        return defaultCartState;
    }

    if (action.type === 'OPEN_CART') {
        return {
            ...state,
            isOpened: true
        }
    }

    if (action.type === 'CLOSE_CART') {
        return {
            ...state,
            isOpened: false
        }
    }

    if (action.type === 'TOGGLE_CART') {
        return {
            ...state,
            isOpened: !state.isOpened
        }
    }

    return defaultCartState;
};

const CartProvider = props => {
    const [cartState, dispatchCartAction] = useReducer(cartReducer, defaultCartState);

    const addItemToCartHandler = item => {
        dispatchCartAction({ type: 'ADD', item: item });
    };

    const increaseItemHandler = id => {
        dispatchCartAction({ type: 'INCREASE', id: id });
    };

    const decreaseItemHandler = id => {
        dispatchCartAction({ type: 'DECREASE', id: id });
    };

    const deleteItemFromCartHandler = id => {
        dispatchCartAction({ type: 'DELETE', id: id });
    };

    const clearCartHandler = () => {
        dispatchCartAction({ type: 'CLEAR' });
    };

    const openCartHandler = () => {
        dispatchCartAction({ type: 'OPEN_CART' });
    };

    const closeCartHandler = () => {
        dispatchCartAction({ type: 'CLOSE_CART' });
    };

    const toggleCartHandler = () => {
        dispatchCartAction({ type: 'TOGGLE_CART' });
    };

    const cartContext = {
        items: cartState.items,
        totalAmount: +cartState.totalAmount,
        isOpened: cartState.isOpened,
        addItem: addItemToCartHandler,
        increaseItem: increaseItemHandler,
        decreaseItem: decreaseItemHandler,
        deleteItem: deleteItemFromCartHandler,
        clearCart: clearCartHandler,
        openCart: openCartHandler,
        closeCart: closeCartHandler,
        toggleCart: toggleCartHandler
    };

    return (
        <CartContext.Provider value={cartContext}>
            {props.children}
        </CartContext.Provider>
    );
};

export default CartProvider;