import './Spinner.css';

const Spinner = (props) => {
    const style = {
        width: props.width,
        height: props.height
    }

    return (
        <div className="spinner" style={style} />
    );
};

export default Spinner;