import './LinkButton.css';

const LinkButton = (props) => {
    let classes = 'link-button';

    if (props.className) classes += ' ' + props.className;

    return (
      <button type={props.type} className={classes} onClick={props.onClick}>
          {props.text}
      </button>
    );
};

export default LinkButton;