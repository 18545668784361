import Spinner from "./Spinner";

import './ComponentLoading.css';

const ComponentLoading = (props) => {
    const style = {
        height: props.height,
        justifyContent: props.justifyContent
    }

    return (
        <div className='component-loading d-flex align-items-center justify-content-center' style={style}>
            <Spinner />
        </div>
    );
};

export default ComponentLoading;