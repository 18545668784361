const BurgerButtonIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
            <line x1="1.25" y1="1.75" x2="14.75" y2="1.75" stroke="#CA9D2A" strokeWidth="2.5" strokeLinecap="round"/>
            <line x1="1.25" y1="7.25" x2="14.75" y2="7.25" stroke="#CA9D2A" strokeWidth="2.5" strokeLinecap="round"/>
            <line x1="1.25" y1="12.75" x2="9.69737" y2="12.75" stroke="#CA9D2A" strokeWidth="2.5" strokeLinecap="round"/>
        </svg>
    );
};

export default BurgerButtonIcon;