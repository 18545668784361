import {Fragment} from "react";

import Line from "../Line";

import './MobilePageName.css';

const MobilePageName = (props) => {
    return (
        <Fragment>
            <h1 className='mobile-page-name__title primary-text tablet-only'>{props.text}</h1>
            <Line dark mb='16px' />
        </Fragment>
    );
};

export default MobilePageName;