import './DiscountInfoLine.css';
import {useTranslation} from "react-i18next";

const DiscountInfoLine = (props) => {
    const { t } = useTranslation();
    return (
        <div className='discount-info-line d-flex justify-content-between align-items-center'>
            <span className='discount-info-line__values white-text'>{props.min} - {props.max}</span>
            <span className='discount-info-line__discount primary-text'>{t('profilePage.discount')} {props.discount}%</span>
        </div>
    );
};

export default DiscountInfoLine;