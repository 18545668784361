import './AngleIcon.css';

const SmallAngleIcon = (props) => {
    let position = '';
    if (props.down) {
        position = 'down';
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none" className={position}>
            <path d="M2.12349 0.377991C1.77909 0.0335903 1.21989 0.0335903 0.875487 0.377991C0.531087 0.72239 0.531087 1.28159 0.875487 1.62599L4.70949 5.46119C4.82949 5.61599 4.89669 5.80559 4.89669 6.00359C4.89669 6.20159 4.82949 6.39239 4.70949 6.54599L0.882687 10.3728C0.538287 10.7172 0.538287 11.2764 0.882687 11.6208C1.22709 11.9652 1.78629 11.9652 2.13069 11.6208L7.12389 6.62759C7.28949 6.46199 7.38189 6.23759 7.38189 6.00359C7.38189 5.76959 7.28949 5.54519 7.12389 5.37959L2.12349 0.377991Z" fill="#8E8882"/>
        </svg>
    );
};

export default SmallAngleIcon;