import {memo} from "react";
import {useTranslation} from "react-i18next";

import OwlCarousel from 'react-owl-carousel';
import ProductCard from "../cards/ProductCard";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './ProductCarousel.css';

const ProductCarousel = (props) => {
    const angle = '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" width="36" height="36" xml:space="preserve">\n' +
    '            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>\n' +
    '            <g><path d="M968.5,343.6c28.7-28.7,28.7-75.3,0-104c-28.7-28.7-75.3-28.7-104,0L544.9,559.1c-12.9,10-28.7,15.6-45.2,15.6c-16.5,0-32.4-5.6-45.2-15.6L135.6,240.2c-28.7-28.7-75.3-28.7-104,0c-28.7,28.7-28.7,75.3,0,104l416.1,416.1c13.8,13.8,32.5,21.5,52,21.5c19.5,0,38.2-7.7,52-21.5L968.5,343.6z"/></g>\n' +
    '        </svg>';

    const { i18n } = useTranslation();
    const carouselOptions = {
        loop: true,
        margin: 16,
        dots: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        nav: true,
        navText: [angle, angle],
        responsive: {
            0: {
                items: 1.5,
                margin: 20,
                nav: false
            },
            768: {
                items: 2.5
            },
            992: {
                items: 3.5
            },
            1200: {
                items: 4
            }
        }
    };

    const carouselItems = props.carouselInfo.map((item) => {
        return (
            <div className='item' key={item.id}>
                <ProductCard
                    id={item.product_id}
                    subcategory_id={item.subcategory_id}
                    title={item['product_name_' + i18n.resolvedLanguage]}
                    category={item['category_name_' + i18n.resolvedLanguage]}
                    price={item.price}
                    discount={item.discount}
                    image={item.main_image}
                />
            </div>
        );
    });

    return (
        <div className='product-carousel'>
            <h1 className='product-carousel__title primary-text'>{props.title}</h1>
            <OwlCarousel className='owl-theme' {...carouselOptions}>
                {carouselItems}
            </OwlCarousel>
        </div>
    );
};

export default memo(ProductCarousel);