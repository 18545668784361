import {apiAddress} from "./APILink";

const getDiscountValue = async (loginCtx) => {
    if (loginCtx.isLoggedIn()) {
        const session_id = loginCtx.getSessionId();
        const response = await fetch(apiAddress + 'api/scripts/GlobalDiscount.php?discountValue&session_id=' + session_id, {method: 'get'})
        return response.json();
    }
    return 0;
}

const getCurrentGlobalDiscountInfo = async (totalSum) => {
    const response = await fetch(apiAddress + 'api/scripts/GlobalDiscount.php?discountValue&totalSum=' + totalSum, {method: 'get'})
    return response.json();
}

const getDiscounts = async () => {
    const response = await fetch(apiAddress + 'api/scripts/GlobalDiscount.php?getDiscounts', {method: 'get'})
    return response.json();
};

export {getDiscountValue, getDiscounts, getCurrentGlobalDiscountInfo};