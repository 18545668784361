import {Link} from "react-router-dom";
import {priceProcessing} from "../../helpers/Processors";
import {useTranslation} from "react-i18next";

import SidebarHeader from "../../components/UI/Headers/SidebarHeader";
import CartInfoLine from "../../components/UI/CartInfoLine";
import Line from "../../components/UI/Line";
import Checkbox from "../../components/UI/Form/Checkbox";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import SidebarContainer from "../../components/container/SidebarContainer";
import Spinner from "../../components/loading/Spinner";

import './CheckoutTotal.css';

const CheckoutTotal = (props) => {
    const { t } = useTranslation();
    const agreeCheckbox = (
        <span className='gray-text'>{t('checkoutPage.agreeCheckbox1')} <Link className='gray-text' to='/info'>{t('checkoutPage.agreeCheckbox2')}</Link></span>
    );

    const totalPrice = priceProcessing(props.totalAmount);
    let finalPrice = props.totalAmountDiscounted;
    if (props.isExpress) {
        finalPrice += 100;
    }
    let freeDelivery = true;
    if (finalPrice < 200) {
        freeDelivery = false;
        finalPrice += 50;
    }
    finalPrice = priceProcessing(finalPrice);

    return (
        <aside className='checkout-total'>
            <SidebarHeader text={t('cart.sidebarHeader')} />
            <SidebarContainer>
                <CartInfoLine title={t('cart.sidebarHeader')} price={totalPrice} />
                {!freeDelivery && <CartInfoLine title={t('cart.delivery')} price='50 MDL' />}
                {/*<p className='light-text checkout-total__info'>{t('cart.message')}</p>*/}
            </SidebarContainer>
            <Line dark />
            <SidebarContainer className='d-flex justify-content-between'>
                <Checkbox text={t('checkoutPage.expressDelivery')} value={props.isExpress} onChange={() => props.onExpress(prevState => !prevState)} />
                <p className='primary-text cart-info__price'>+MDL 100.00</p>
            </SidebarContainer>
            <Line dark />
            <SidebarContainer>
                <Checkbox text={agreeCheckbox} value={props.agreement} onChange={() => {props.onAgreement(prevState => !prevState)}} />
                <div className='d-flex justify-content-end'>
                    <CartInfoLine title={t('cart.totalSum')} price={finalPrice} break />
                </div>
            </SidebarContainer>
            <SidebarContainer>
                <div className='d-flex justify-content-center'>
                    <SolidButton className='cart-info__buyButton cart-info__checkoutButton' primary text={t('cart.makeOrderButton')} onClick={props.onSubmit} disabled={!props.valid} />
                </div>
                <div className='mt-1 d-flex justify-content-center'>
                    {props.showLoad && <Spinner width={'20px'} height={'20px'} />}
                </div>
            </SidebarContainer>
        </aside>
    )
};

export default CheckoutTotal;