const TriangleOpened = (props) => {
    let width = 14;
    let height = 11;

    if (props.width) width = props.width;
    if (props.height) height = props.height;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 11" fill="none">
            <path d="M7.20867 9.32196C7.10986 9.47171 6.89014 9.47171 6.79133 9.32196L1.72103 1.63769C1.61136 1.47147 1.73056 1.25 1.9297 1.25L12.0703 1.25C12.2694 1.25 12.3886 1.47147 12.279 1.63769L7.20867 9.32196Z" stroke="#CA9D2A" strokeWidth="1.5"/>
        </svg>
    );
}

export default TriangleOpened;