const initialOrderReducer = {
    order: [
        {
            id: null,
            date: null,
            totalAmount: null,
            orderContent: [
                {
                    id: null,
                    amount: null,
                    name_ru: '',
                    name_ro: '',
                    price: null
                }
            ]
        }
    ],
    orderLoaded: false
};

const orderReducer = (state, action) => {
    switch (action.type) {
        case 'SetLoadingOrders':
            return {
                ...state,
                orderLoaded: false
            }
        case 'LoadOrders':
            return {
                ...state,
                order: [...action.payload],
                orderLoaded: true
            };
        default:
            console.log('Something went wrong!');
            break;
    }
};

export {orderReducer, initialOrderReducer};