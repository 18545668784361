import Backdrop from "../blocks/Header/Backdrop";
import SolidButton from "../UI/Buttons/SolidButton";
import HollowButton from "../UI/Buttons/HollowButton";

import './Modal.css';
import Logo from "../../assets/icons/Logo";
import {useTranslation} from "react-i18next";

const AgeWarningModal = (props) => {
    const { t } = useTranslation();

    return (
        <Backdrop className='d-flex align-items-center justify-content-center'>
            <aside className='modal__body text-center'>
                <div><Logo className='modal__logo' /></div>
                <h1 className='modal__title modal__center secondary-text w-50'>
                    {t('modals.ageWarning.warningMessage')}
                </h1>
                <div className='mt-2'>
                    <SolidButton primary text={t('modals.ageWarning.acceptButton')} onClick={props.onClose} className='w-100' />
                </div>
                <div className='mt-2'>
                    <HollowButton secondary text={t('modals.ageWarning.declineButton')} className='w-100' />
                </div>
                <p className='modal__info modal__center dark-text w-75'>
                    {t('modals.ageWarning.warningInfo')}
                </p>
            </aside>
        </Backdrop>
    );
};

export default AgeWarningModal;