import './Timer.css';

const Timer = (props) => {
    /*const [timerSeconds, setTimerSeconds] = useState(props.seconds);

    useEffect(() => {
        let interval = null;
        if (timerSeconds > 0) {
            interval = setInterval(() => {
                setTimerSeconds(seconds => seconds - 1);
                props.onTick(timerSeconds);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerSeconds]);*/

    const hours = Math.floor(props.seconds / 3600);
    const minutes = Math.floor((props.seconds - 3600 * hours) / 60);
    const seconds = props.seconds - minutes * 60 - hours * 3600;

    const displayHours = hours < 10 ? '0' + hours : hours;
    const displayMinutes = minutes < 10 ? '0' + minutes : minutes;
    const displaySeconds = seconds < 10 ? '0' + seconds : seconds;

    return (
        <div className='timer primary-text d-flex justify-content-center align-items-center'>
            <span>{displayHours}</span>:<span>{displayMinutes}</span>:<span>{displaySeconds}</span>
        </div>
    );
};

export default Timer;