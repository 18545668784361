import './Input.css';

const Input = (props) => {
    let classesInit = 'input-control';
    if (props.className) classesInit += ' ' + props.className;
    if (props.gold) classesInit += ' gold';
    if (props.isError) classesInit += ' error';

    let containerClass = 'w-100';
    if (!props.nomargin) containerClass += ' input-container';

    return (
        <div className={containerClass}>
            <input
                className={classesInit}
                type={props.type}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
                onBlur={props.onBlur}
                disabled={props.disabled}
                readOnly={props.readonly}
            />
            {/*<p className=''></p>*/}
        </div>
    );
};

export default Input;