import './Feature.css';

const Feature = (props) => {
    return (
        <div className='mainPage-feature d-flex'>
            <div className='mainPage-feature__icon'>{props.icon}</div>
            <div className='mainPage-feature__info'>
                <h2 className='mainPage-feature__title light-text'>{props.title}</h2>
                <p className='mainPage-feature__text light-text'>
                    {props.text}
                </p>
            </div>
        </div>
    );
};

export default Feature;