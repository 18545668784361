import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Fragment, useContext, useEffect, useState} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import {fetchCart} from "./api/Cart";
import CartContext from "./store/cart-context";
import LoginContext from "./store/login-context";
import ScrollToTop from "./helpers/ScrollToTop";

import Header from "./components/blocks/Header/Header";
import MainPage from "./pages/MainPage/MainPage";
import CategorySelectPage from "./pages/CategorySelectPage/CategorySelectPage";
import CategoryBrowserPage from "./pages/CategoryBrowser/CategoryBrowserPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import CartPage from "./pages/CartPage/CartPage";
import Checkout from "./pages/Checkout/Checkout";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import Login from "./pages/Authorization/Login";
import Registration from "./pages/Authorization/Registration";
import Restore from "./pages/Authorization/Restore";
import ContactPage from "./pages/Contacts/ContactPage";
import InfoPage from "./pages/InfoPage/InfoPage";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import Footer from "./components/blocks/Footer/Footer";
import AgeWarningModal from "./components/modals/AgeWarningModal";
import ChangePassword from "./pages/Authorization/ChangePassword";
import Cookies from "./components/Cookies";

import './App.css';
import TimerModal from "./components/modals/TimerModal";
import FloatButton from "./components/UI/Buttons/FloatButton";

function App() {
    const [isAgeChecked, setIsAgeChecked] = useState(localStorage.getItem('checked') === '1');
    const [areCookiesAccepted, setAreCookiesAccepted] = useState(localStorage.getItem('cookies') === '1');
    const [timerSeconds, setTimerSeconds] = useState(localStorage.getItem('discountTimer') ?? 900);
    const [isDiscountModalOpened, setDiscountModalOpened] = useState(false);
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);
    const location = useLocation();

    const warningModalCloseHandler = () => {
        setIsAgeChecked(true);
        localStorage.setItem('checked', '1');
    };

    const acceptCookiesHandler = () => {
        setAreCookiesAccepted(true);
    }

    const toggleDiscountModal = () => {
        setDiscountModalOpened(prevState => !prevState);
    };

    useEffect(() => {
        const fetchData = async () => {
            fetchCart(cartCtx, loginCtx);
        };
        fetchData();
    }, []);

    useEffect(() => {
        let interval = null;
        if (timerSeconds > 0) {
            interval = setInterval(() => {
                setTimerSeconds(seconds => {
                    localStorage.setItem('discountTimer', '' + (seconds - 1));
                    return seconds - 1;
                })
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerSeconds]);

  return (
        <Fragment>
            <ScrollToTop />
            <Header />
            <TransitionGroup>
                <CSSTransition timeout={450} classNames='fade' key={location.key}>
                    <Routes location={location}>
                        <Route path='/' element={<MainPage />} />
                        <Route path='/shop' element={<CategorySelectPage />} />
                        <Route path='/shop/brand/:brand' element={<CategoryBrowserPage />} />
                        <Route path='/shop/:subcategory' element={<CategoryBrowserPage />} />
                        <Route path='/shop/:subcategory/:id' element={<ProductPage toggleDiscountModal={toggleDiscountModal} />} />
                        <Route path='/cart' element={<CartPage />} />
                        <Route path='/checkout' element={<Checkout />} />
                        <Route path='/profile' element={<ProfilePage />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/registration' element={<Registration />} />
                        <Route path='/restore' element={<Restore />} />
                        <Route path='/restore/:r/:e' element={<ChangePassword />} />
                        <Route path='/contacts' element={<ContactPage />} />
                        <Route path='/info' element={<InfoPage />} />
                        <Route path='/success' element={<SuccessPage />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
            <FloatButton onClick={toggleDiscountModal} />
            <Footer />
            {!areCookiesAccepted && <Cookies onAccept={acceptCookiesHandler} />}
            {!isAgeChecked && <AgeWarningModal onClose={warningModalCloseHandler} />}
            {isDiscountModalOpened && <TimerModal discountTimerSeconds={timerSeconds} onClose={toggleDiscountModal} />}
        </Fragment>
  );
}

export default App;
