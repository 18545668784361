import {useEffect, useReducer, useState} from "react";
import LazyLoad from 'react-lazyload';
import {categoryBrowserReducer, initialCategoryBrowserReducer} from "../../reducers/CategoryBrowserReducer";
import {breadcrumbsReducer, initialBreadcrumbsReducer} from "../../reducers/BreadcrumbsReducer";
import {getBreadcrumbsPath} from "../../api/Breadcrumbs";
import {useNavigate, useParams} from "react-router-dom";
import {getProducts, getProductsByBrand} from "../../api/ProductInfo";
import {useTranslation} from "react-i18next";

import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import SidebarHeader from "../../components/UI/Headers/SidebarHeader";
import Checkbox from "../../components/UI/Form/Checkbox";
import MultiRangeSlider from "../../components/UI/Form/MultiRangeSlider";
import HollowButton from "../../components/UI/Buttons/HollowButton";
import ProductCard from "../../components/cards/ProductCard";
import TopBrands from "../../components/blocks/TopBrands/TopBrands";
import Instagram from "../../components/blocks/Instagram/Instagram";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Ball from "../../components/Ball";
import ComponentLoading from "../../components/loading/ComponentLoading";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import CEO from "../../components/blocks/CEO/CEO";

import './CategoryBrowserPage.css';

const CategoryBrowserPage = () => {
    const {subcategory, brand} = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [opened, setOpened] = useState(null);
    const [state, dispatch] = useReducer(categoryBrowserReducer, initialCategoryBrowserReducer);
    const [breadcrumbsState, breadcrumbsDispatch] = useReducer(breadcrumbsReducer, initialBreadcrumbsReducer);

    useEffect(() => {
        if (brand === undefined) {
            getBreadcrumbsPath(breadcrumbsDispatch, subcategory);
        }
        const fetchData = async () => {
            let data;
            if (subcategory === undefined) {
                const brandName = brand.replace('_', ' ');
                data = await getProductsByBrand(dispatch, brandName);
            } else {
                data = await getProducts(dispatch, subcategory);
            }
            if (data.products.length <= 0) {
                navigate('/shop');
            } else {
                dispatch({type: 'LoadProducts', payload: data});
            }
        };

        fetchData();
    }, [subcategory]);

    const openFilterMenuHandler = () => {
      setOpened(prevState => {
          if (prevState === null || prevState === false) {
              document.body.style.overflow = "hidden";
              return true;
          }
          else {
              document.body.style.overflow = "auto";
              return false;
          }
      });
    };

    let  brandName;
    if (subcategory === undefined) {
        brandName = brand.replace('_', ' ');
    }

    let breadcrumbHierarchy = [
        {
            name: t('categoryBrowserPage.breadcrumbs.categories'),
            url: '/shop'
        }
    ];

    if (subcategory === undefined) {
        breadcrumbHierarchy.push({
            name: brandName
        });
    } else {
        breadcrumbHierarchy.push({
            name: breadcrumbsState.breadcrumbs['category_' + i18n.resolvedLanguage]
        });
        breadcrumbHierarchy.push({
            name: breadcrumbsState.breadcrumbs['subcategory_' + i18n.resolvedLanguage]
        });
    }

    const filterProductByBrand = (brand_id) => {
        if (state.productsLoaded) {
            Promise.resolve(dispatch({type: 'SetLoadingProducts'}))
                .then(() => dispatch({
                    type: 'ChangeFilters',
                    payload: {
                        brand: brand_id
                    }
                }));
        }
    }

    const filterByProductPrice = (min, max) => {
        if (state.productsLoaded) {
            Promise.resolve(dispatch({type: 'SetLoadingProducts'}))
                .then(() => dispatch({
                    type: 'ChangeFilters',
                    payload: {
                        minPrice: min,
                        maxPrice: max
                    }
                }));
        }
    }

    let categoryBrowserItems = <ComponentLoading height={'50vh'} />;
    if (state.productsLoaded) {
        categoryBrowserItems = state.filteredProducts.map((item) => {
            return (
                <Col xl='4' md='6' sm='6' key={item.product_id} className='category-browser__product'>
                    <LazyLoad>
                        <ProductCard
                            id={item.product_id}
                            subcategory_id={item.subcategory_id}
                            title={item['product_name_' + i18n.resolvedLanguage]}
                            category={item['category_name_' + i18n.resolvedLanguage]}
                            price={item.price}
                            discount={item.discount}
                            image={item.main_image}
                        />
                    </LazyLoad>
                </Col>
            );
        });
    }

    const brandsFilter = state.brands.map((brand) => {
        return <Checkbox text={brand.name} key={brand.id} onClick={() => filterProductByBrand(brand.id)} />
    });

    return (
        <main className='p-relative'>
            <Container className='z-10'>
                <Row>
                    <Col col='12'>
                        {(subcategory === undefined || breadcrumbsState.breadcrumbsLoaded) && (
                            <Breadcrumbs
                                hierarchy={ breadcrumbHierarchy }
                            />
                        )}
                    </Col>
                    <Col col='4'>
                        <div className='d-flex justify-content-end tablet-only'>
                            <HollowButton text={t('categoryBrowserPage.filters.openFiltersButton')} onClick={openFilterMenuHandler} />
                        </div>
                        <aside className={`category-browser__filter-sidebar ${opened === null ? '' : opened ? 'opened' : 'closed'}`}>
                            <SidebarHeader text={t('categoryBrowserPage.filters.header')} />
                            <div className='category-browser__filter'>
                                {brandsFilter}
                            </div>
                            <SidebarHeader text={t('categoryBrowserPage.filters.price')} />
                            <div className='category-browser__filter'>
                                <MultiRangeSlider
                                    min={0}
                                    max={10000}
                                    onChange={({ min, max }) => filterByProductPrice(min, max)}
                                />
                            </div>
                            <div className='category-browser__filter tablet-only' style={{marginBottom: '100px'}}>
                                <SolidButton primary text={t('categoryBrowserPage.filters.applyButton')} onClick={openFilterMenuHandler} className='w-100' />
                            </div>
                        </aside>
                    </Col>
                    <Col col='8'>
                        <Row>
                            {categoryBrowserItems}
                        </Row>
                    </Col>
                </Row>
                <Ball
                    background='#E4B948'
                    top='0'
                    right='0'
                />
                <Ball
                    background='#FE7A00'
                    top='600px'
                    left='0'
                />
            </Container>
            <CEO subcategory={subcategory} />
            <TopBrands />
            <Instagram />
        </main>
    );
}

export default CategoryBrowserPage;