const initialMainPageReducer = {
    promoCarouselInfo: {
        id: null,
        image: null,
        image_mobile: null,
        title_ru: '',
        title_ro: '',
        subtitle_ru: '',
        subtitle_ro: '',
        button_ro: ''
    },
    promoLoaded: false,
    newCarouselInfo: {
        id: null,
        product_id: null,
        product_name_ro: '',
        product_name_ru: '',
        price: null,
        discount: null,
        image: null
    },
    newLoaded: false,
    popularCarouselInfo: {
        id: null,
        product_id: null,
        product_name_ro: '',
        product_name_ru: '',
        price: null,
        discount: null,
        image: null
    },
    popularLoaded: false,
    discountCarouselInfo: {
        id: null,
        product_id: null,
        product_name_ro: '',
        product_name_ru: '',
        price: null,
        discount: null,
        image: null
    },
    discountLoaded: false
};

const mainPageReducer = (state, action) => {
    switch (action.type) {
        case 'SetLoadingPromoCarousel':
            return {
                ...state,
                promoLoaded: false
            }
        case 'LoadPromoCarousel':
            return {
                ...state,
                promoCarouselInfo: [...action.payload],
                promoLoaded: true
            };
        case 'SetLoadingNewCarousel':
            return {
                ...state,
                newLoaded: false
            }
        case 'LoadNewCarousel':
            return {
                ...state,
                newCarouselInfo: [...action.payload],
                newLoaded: true
            };
        case 'SetLoadingPopularCarousel':
            return {
                ...state,
                popularLoaded: false
            }
        case 'LoadPopularCarousel':
            return {
                ...state,
                popularCarouselInfo: [...action.payload],
                popularLoaded: true
            };
        case 'SetLoadingDiscountCarousel':
            return {
                ...state,
                discountLoaded: false
            }
        case 'LoadDiscountCarousel':
            return {
                ...state,
                discountCarouselInfo: [...action.payload],
                discountLoaded: true
            };
        default:
            console.log('Something went wrong!');
            break;
    }
};

export {mainPageReducer, initialMainPageReducer};