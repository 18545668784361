import './Line.css';

const Line = (props) => {
    let color = 'line';
    if (props.dark) {
        color += ' dark'
    } else {
        color += ' light'
    }

    const style = {
        marginTop: props.mt ? props.mt : '0',
        marginBottom: props.mb ? props.mb : '0'
    }

    return <div className={color} style={style} />;
};

export default Line;