const PhoneIcon = (props) => {
    const width = props.width ? props.width : 17;
    const height = props.height ? props.height : 13;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 13" fill="none" style={props.style}>
            <path d="M9.47298 7.77388C8.62408 8.62278 8.62408 9.47298 7.77388 9.47298C6.92368 9.47298 6.07478 8.62278 5.22588 7.77388C4.37698 6.92498 3.52678 6.07478 3.52678 5.22588C3.52678 4.37698 4.37568 4.37698 5.22588 3.52678C6.07478 2.67788 3.52678 0.129883 2.67788 0.129883C1.82898 0.129883 0.129883 2.67788 0.129883 2.67788C0.129883 4.37698 1.87448 7.82068 3.52678 9.47298C5.17908 11.1253 8.62278 12.8699 10.3219 12.8699C10.3219 12.8699 12.8699 11.1708 12.8699 10.3219C12.8699 9.47298 10.3219 6.92498 9.47298 7.77388Z" fill="#FE7A00"/>
        </svg>
    );
};

export default PhoneIcon;