import {useTranslation} from "react-i18next";

import Container from "../../container/Container";
import Row from "../../grid/Row";
import Col from "../../grid/Col";
import HollowButton from "../../UI/Buttons/HollowButton";

import './Instagram.css';
import Inst from '../../../assets/img/Instagram_subscribe.png';
import Fume from '../../../assets/img/fume.png';

const Instagram = () => {
    const { t } = useTranslation();

    const goToInstagram = () => {
        window.open('https://www.instagram.com/torsedormd/?igshid=NDBlY2NjN2I%3D', '_blank').focus();
    };

    return (
        <section className='instagram-subscribe' style={{backgroundImage: `url(${Fume})`}}>
            <Container>
                <Row>
                    <Col col={6} className='d-flex align-items-center justify-content-center'>
                        <div>
                            <h1 className='instagram-subscribe__header primary-text'>{t('InstagramBlock.header')}</h1>
                            <p className='instagram-subscribe__text light-text'>
                                {t('InstagramBlock.subheader')}
                            </p>
                            <HollowButton
                                onClick={goToInstagram}
                                text={t('InstagramBlock.subscribeButton')}
                            />
                        </div>
                    </Col>
                    <Col col={6}>
                        <img src={Inst} className='w-100' alt='' />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Instagram;