import {priceProcessing} from "../../helpers/Processors";

import './DiscountProgress.css';
import {useEffect, useState} from "react";
import {getCurrentGlobalDiscountInfo} from "../../api/GlobalDiscount";
import {useTranslation} from "react-i18next";

const DiscountProgress = (props) => {
    const { t } = useTranslation();
    const [maxProgress, setMaxProgress] = useState(null);
    const currentProgress = props.progress;

    useEffect(() => {
        const fetchCurrentDiscount = async () => {
            const currentDiscountInfo = await getCurrentGlobalDiscountInfo(currentProgress);
            if (currentDiscountInfo.max !== null) setMaxProgress(currentDiscountInfo.max);
        };
        fetchCurrentDiscount();
    }, []);

    let fillingPercent = 100;
    if (currentProgress < +maxProgress) fillingPercent = (currentProgress * 100 / +maxProgress) % 100;

    const transform = {
        transform: `translateX(${-100 + fillingPercent}%`
    };

    const currentProgressDemo = priceProcessing(props.progress, true);
    let maxProgressDemo = t('profilePage.maxDiscount');
    if (maxProgress !== null) maxProgressDemo = priceProcessing(maxProgress, true);

    return (
        <div className='discount-progress d-flex align-items-center'>
            <span className='primary-text discount-progress__price'>{currentProgressDemo}</span>
            <div className='discount-progress__body'>
                <div className='discount-progress__filling w-100' style={transform} />
            </div>
            <span className='primary-text discount-progress__price'>{maxProgressDemo}</span>
        </div>
    );
};

export default DiscountProgress;