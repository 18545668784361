import LoginContext from "./login-context";

const LoginProvider = props => {

    const isLoggedIn = () => {
        return localStorage.getItem('loggedIn') !== null;
    }

    const loginHandler = (session_id) => {
        localStorage.setItem('loggedIn', session_id);
    }

    const logoutHandler = () => {
        localStorage.removeItem('loggedIn');
    }

    const getSessionIdHandler = () => {
        return localStorage.getItem('loggedIn');
    }

    const loginContext = {
        isLoggedIn: isLoggedIn,
        getSessionId: getSessionIdHandler,
        login: loginHandler,
        logout: logoutHandler
    };

    return (
        <LoginContext.Provider value={loginContext}>
            {props.children}
        </LoginContext.Provider>
    );
};

export default LoginProvider;