import {apiAddress} from "./APILink";

const getCurrentUser = async (loginCtx) => {
    if (loginCtx.isLoggedIn()) {
        const session_id = loginCtx.getSessionId();
        const response = await fetch(apiAddress + 'api/scripts/User.php?currentUser=' + session_id, {method: 'get', credentials: "same-origin"});
        return await response.json();
    }
    return {error: 1};
};

const register = async (fName, lName, email, phone, password, repeatPassword, isSubscribed = false) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            fName, lName, email, phone, password, repeatPassword, isSubscribed
        }),
    };
    const response = await fetch(apiAddress + 'api/scripts/Register.php', requestOptions);
    return await response.json();
}

const login = async (email, password) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email, password
        }),
    };
    const response = await fetch(apiAddress + 'api/scripts/Login.php', requestOptions);
    return await response.json();
};

const logout = async (loginCtx) => {
    if (loginCtx.isLoggedIn()) {
        const session_id = loginCtx.getSessionId();
        return await fetch(apiAddress + 'api/scripts/User.php?logout=' + session_id , {method: 'get'});
    }
};

const restore = async (email) => {
    const response = await fetch(apiAddress + 'api/scripts/Restore.php?email=' + email , {method: 'get'});
    return await response.json();
};

const changePasswordRestore = async (newPassword, r, e) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newPassword
        }),
    };

    const response = await fetch(apiAddress + 'api/scripts/Restore.php?changePassword&r=' + r + '&e=' + e, requestOptions);
    return await response.json();
};

export {register, login, logout, getCurrentUser, restore, changePasswordRestore};