import './CartInfoLine.css';

const CartInfoLine = (props) => {
    let classes = 'd-flex justify-content-between align-items-center';

    if (props.break) classes = 'cart-info-line';
    if (props.className) classes += ' ' + props.className;

    return (
        <div className={classes}>
            <h3 className='white-text cart-info__subtitle'>{props.title}</h3>
            <span className='primary-text cart-info__price'>{props.price}</span>
        </div>
    )
};

export default CartInfoLine;