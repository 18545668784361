import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useInput from "../../hooks/use-input";
import {isEmail, isPhone, isValidPassword} from "../../helpers/Validator";
import {register} from "../../api/Account";
import LoginContext from "../../store/login-context";

import LinkButton from "../../components/UI/Buttons/LinkButton";
import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import GoogleAuthButton from "../../components/UI/Buttons/GoogleAuthButton";
import Input from "../../components/UI/Form/Input";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import Checkbox from "../../components/UI/Form/Checkbox";
import Ball from "../../components/Ball";
import ErrorMessage from "../../components/UI/Error/ErrorMessage";

import './Authorization.css';
import {useTranslation} from "react-i18next";

const Registration = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const loginCtx = useContext(LoginContext);

    const [errorMessage, setErrorMessage] = useState('');
    const [isSubcribed, setIsSubcribed] = useState(false);

    useEffect(() => {
        if (loginCtx.isLoggedIn()) navigate('/profile');
    }, []);

    const {
        value: fName,
        isValid: enteredFNameIsValid,
        hasError: fNameInputHasError,
        valueChangeHandler: fNameChangedHandler,
        inputBlurHandler: fNameBlurHandler
    } = useInput(value => value.trim() !== '');

    const {
        value: lName,
        isValid: enteredLNameIsValid,
        hasError: lNameInputHasError,
        valueChangeHandler: lNameChangedHandler,
        inputBlurHandler: lNameBlurHandler
    } = useInput(value => value.trim() !== '');

    const {
        value: email,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        reset: emailReset,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput(value => isEmail(value));

    const {
        value: phone,
        isValid: enteredPhoneIsValid,
        hasError: phoneInputHasError,
        reset: phoneReset,
        valueChangeHandler: phoneChangedHandler,
        inputBlurHandler: phoneBlurHandler
    } = useInput(value => isPhone(value));

    const {
        value: password,
        isValid: enteredPasswordIsValid,
        hasError: passwordInputHasError,
        valueChangeHandler: passwordChangedHandler,
        inputBlurHandler: passwordBlurHandler
    } = useInput(value => isValidPassword(value));

    const {
        value: password2,
        isValid: enteredPassword2IsValid,
        hasError: password2InputHasError,
        reset: password2Reset,
        valueChangeHandler: password2ChangedHandler,
        inputBlurHandler: password2BlurHandler
    } = useInput(value => isValidPassword(value) && value === password);

    const validForm = enteredFNameIsValid && enteredLNameIsValid && enteredEmailIsValid && enteredPhoneIsValid
                        && enteredPasswordIsValid && enteredPassword2IsValid;

    const registerButtonHandler = async (e) => {
        e.preventDefault();
        if (validForm) {
            const response = await register(fName, lName, email, phone, password, password2, isSubcribed);
            if (response.error === "ok") {
                setErrorMessage('');
                loginCtx.login(response.session_id);
                navigate(-2);
            } else if (response.error === 1) {
                setErrorMessage(t('loginForms.error.emptyFields'));
            } else if (response.error === 2) {
                setErrorMessage(t('loginForms.error.emailValidation'));
            } else if (response.error === 3) {
                phoneReset();
                setErrorMessage(t('loginForms.error.phoneFormat'));
            } else if (response.error === 4) {
                setErrorMessage(t('loginForms.error.passwordLength'));
            } else if (response.error === 5) {
                password2Reset();
                setErrorMessage(t('loginForms.error.passwordMismatch'));
            } else if (response.error === 6) {
                emailReset();
                setErrorMessage(t('loginForms.error.emailIsUsed'));
            }
        }
    }

    return  (
        <main>
            <section className='p-relative'>
                <Container>
                    <Row className='auth justify-content-center align-items-center' style={{paddingTop: '60px'}}>
                        <Col sm={12} lg={6}>
                            <Row className='justify-content-center'>
                                <div className='d-flex justify-content-end w-100 desktop-only'>
                                    <LinkButton onClick={() => navigate(-1)} className='light-text' text={t('loginForms.backButton')} />
                                </div>
                                <Col sm={12} lg={8}>
                                    <h1 className='auth__title primary-text text-center'>{t('loginForms.registrationHeader')}</h1>
                                    {/*<GoogleAuthButton />*/}
                                    <form className='auth__padding-big' onSubmit={registerButtonHandler}>
                                        <Input
                                            type='text'
                                            placeholder={t('loginForms.name')}
                                            value={fName}
                                            onChange={fNameChangedHandler}
                                            onBlur={fNameBlurHandler}
                                            isError={fNameInputHasError}
                                        />
                                        <Input
                                            type='text'
                                            placeholder={t('loginForms.surname')}
                                            value={lName}
                                            onChange={lNameChangedHandler}
                                            onBlur={lNameBlurHandler}
                                            isError={lNameInputHasError}
                                        />
                                        <Input
                                            type='email'
                                            placeholder='Email'
                                            value={email}
                                            onChange={emailChangedHandler}
                                            onBlur={emailBlurHandler}
                                            isError={emailInputHasError}
                                        />
                                        <Input
                                            type='tel'
                                            placeholder={t('loginForms.phoneNumber')}
                                            value={phone}
                                            onChange={phoneChangedHandler}
                                            onBlur={phoneBlurHandler}
                                            isError={phoneInputHasError}
                                        />
                                        <Input
                                            type='password'
                                            placeholder={t('loginForms.password')}
                                            value={password}
                                            onChange={passwordChangedHandler}
                                            onBlur={passwordBlurHandler}
                                            isError={passwordInputHasError}
                                        />
                                        <Input
                                            type='password'
                                            placeholder={t('loginForms.repeatPassword')}
                                            value={password2}
                                            onChange={password2ChangedHandler}
                                            onBlur={password2BlurHandler}
                                            isError={password2InputHasError}
                                        />
                                        <Checkbox text={t('loginForms.newsletterSubscribe')} onClick={() => setIsSubcribed(prevState => !prevState)} dark className='auth__padding-small' />
                                        {errorMessage !== '' && <ErrorMessage errorMessage={errorMessage} />}
                                        <div className='d-flex justify-content-center auth__padding-big'>
                                            <SolidButton className='w-75' text={t('loginForms.registrationButton')} disabled={!validForm} primary onClick={registerButtonHandler} />
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <p className='dark-text auth__control text-center'>
                                                {t('loginForms.registrationAgreement1')}
                                                <LinkButton className='auth__control dark-text text-underline' text={t('loginForms.registrationAgreement2')} /> {t('loginForms.registrationAgreement3')}
                                            </p>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Ball
                        background='#FE0098'
                        bottom={0}
                        left={0}
                    />
                    <Ball
                        background='#E4B948'
                        top={0}
                        right={0}
                    />
                </Container>
            </section>
        </main>
    );
};

export default Registration;