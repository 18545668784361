import { Link } from "react-router-dom";

import './Breadcrumbs.css';

const Breadcrumbs = (props) => {
    const breadcrumbItems = props.hierarchy.map((item, index) => {
        if (item.url !== undefined) return <Link className='breadcrumbs__item' key={index} to={item.url}>{item.name}</Link>;
        return <span className='breadcrumbs__item' key={index}>{ item.name }</span>;
    });

    return (
      <nav className='breadcrumbs'>
          { breadcrumbItems }
      </nav>
    );
};

export default Breadcrumbs;