const initialCategoryBrowserReducer = {
    initialProducts: [],
    filteredProducts: [],
    brands: [],
    productsLoaded: false,
    filters: {
        brands: [],
        minPrice: 0,
        maxPrice: 10000
    }
};

const categoryBrowserReducer = (state, action) => {
    switch (action.type) {
        case 'SetLoadingProducts':
            return {
                ...state,
                productsLoaded: false
            }
        case 'LoadProducts':
            return {
                ...state,
                brands: [...action.payload.brands],
                initialProducts: [...action.payload.products],
                filteredProducts: [...action.payload.products],
                productsLoaded: true
            }
        case 'ChangeFilters':
            let filters = {...state.filters};
            if (action.payload.minPrice) filters.minPrice = action.payload.minPrice;
            if (action.payload.maxPrice) filters.maxPrice = action.payload.maxPrice;
            if (action.payload.brand) {
                const withoutBrand = filters.brands.filter(brand => brand !== action.payload.brand);
                if (withoutBrand.length === filters.brands.length) {
                    filters.brands = [...filters.brands, action.payload.brand];
                } else {
                    filters.brands = [...withoutBrand];
                }
            }

            const filteredProducts = state.initialProducts.filter(product => {
                const brandCheck = filters.brands.length === 0 ? true : filters.brands.includes(product.brand_id);
                const minPriceCheck = (
                    (product.discount === null && product.price >= filters.minPrice) ||
                    (product.discount !== null && product.discount >= filters.minPrice)
                );
                const maxPriceCheck = (
                    (product.discount === null && product.price <= filters.maxPrice) ||
                    (product.discount !== null && product.discount <= filters.maxPrice)
                );
                return (brandCheck && minPriceCheck && maxPriceCheck);
            });
            return {
                ...state,
                productsLoaded: true,
                filteredProducts: [...filteredProducts],
                filters: {...filters}
            };
        default:
            console.log("error");
    }
};

export {categoryBrowserReducer, initialCategoryBrowserReducer};