import {useContext, useEffect, useReducer, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getCurrentUser, logout} from "../../api/Account";
import {initialOrderReducer, orderReducer} from "../../reducers/OrderReducer";
import {getOrders} from "../../api/Order";
import {priceProcessing} from "../../helpers/Processors";
import CartContext from "../../store/cart-context";
import LoginContext from "../../store/login-context";

import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Line from "../../components/UI/Line";
import DiscountProgress from "./DiscountProgress";
import OrderHistory from "../../assets/icons/OrderHistory";
import Order from "./Order";
import DiscountInfo from "./DiscountInfo";
import LinkButton from "../../components/UI/Buttons/LinkButton";
import Ball from "../../components/Ball";
import ComponentLoading from "../../components/loading/ComponentLoading";

import './ProfilePage.css';
import {useTranslation} from "react-i18next";

const ProfilePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);

    const [profileInfo, setProfileInfo] = useState({});
    const [orderState, orderDispatch] = useReducer(orderReducer, initialOrderReducer);

    const hierarchy = [
        {
            name: t('profilePage.breadcrumbs.profile')
        }
    ];

    useEffect(() => {
        if (!loginCtx.isLoggedIn()) navigate('/login');
        const fetchData = async () => {
            const response = await getCurrentUser(loginCtx);
            if (response.error) loginCtx.logout();
            else setProfileInfo(response);
        }
        getOrders(loginCtx, orderDispatch);
        fetchData();
    }, []);

    const logoutHandler = async () => {
        const response = await logout(loginCtx);
        if (response.ok) {
            cartCtx.clearCart();
            loginCtx.logout();
            navigate('/login');
        }
    }

    let totalAmount = 0;
    let orders = <ComponentLoading height={'20vh'} />;
    if (orderState.orderLoaded) {
        for (let order of orderState.order) {
            totalAmount += +order.totalAmount;
        }
        orders = orderState.order.map((order) => {
            return <Order
                        key={order.id}
                        id={order.id}
                        date={order.date}
                        totalAmount={order.totalAmount}
                        orderContent={order.orderContent}
                    />
        });
    }

    return (
        <main className='profile-page p-relative'>
            <Container>
                <Row>
                    <Col col={12}>
                        <Breadcrumbs hierarchy={hierarchy} />
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <Line dark />
                        <div className='profile-page__vertical-padding'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <h1 className='profile-page__greeting white-text'>{t('profilePage.greeting')} {profileInfo.fName}!</h1>
                                    <p className='profile-page__sum primary-text'>{t('profilePage.totalOrdersSum')} <span className='secondary-text'>{priceProcessing(totalAmount)}</span></p>
                                </div>
                                <LinkButton className='light-text' text={t('profilePage.logoutButton')} onClick={logoutHandler} />
                            </div>
                            {orderState.orderLoaded ? <DiscountProgress progress={totalAmount} /> : <ComponentLoading height={'10vh'} />}
                        </div>
                        <Line dark />
                        <div className='d-flex align-items-center' style={{marginTop: '40px', marginBottom: '60px'}}>
                            <OrderHistory />
                            <h2 className='profile-page__title white-text'>{t('profilePage.orderHistoryHeader')}</h2>
                        </div>
                        {orders}
                    </Col>
                    <Col sm={12} md={12} lg={4}>
                        <DiscountInfo />
                    </Col>
                </Row>
                <Ball
                    background='#FE0098'
                    bottom='0'
                    right={0}
                />
                <Ball
                    background='#FE7A00'
                    bottom='0'
                    left={0}
                />
                <Ball
                    background='#E4B948'
                    top={0}
                    right={0}
                />
            </Container>
        </main>
    );
};

export default ProfilePage;