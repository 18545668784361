import {Link} from "react-router-dom";

import Col from "../../components/grid/Col";
import TriangleClosed from "../../assets/icons/TriangleClosed";

import './CategorySlider.css';
import {useTranslation} from "react-i18next";

const CategorySlider = (props) => {
    const { i18n } = useTranslation();
    const sliderMenuItems = props.subcategories.map(item => {
        const link = `/shop/${item.id}`;
        return (
            <li key={item.id} className='white-text'><TriangleClosed /> <Link to={link}>{item['title_' + i18n.resolvedLanguage]}</Link></li>
        );
    });
    return (
        <Col col={12} className='category-option__slider'>
            <ul>
                {sliderMenuItems}
            </ul>
        </Col>
    );
};

export default CategorySlider;