import './InfoFeature.css';

const InfoFeature = (props) => {
    let color = 'info-feature__list-text';
    if (props.secondary) color += ' secondary-text';
    else color += ' primary-text';

    return (
        <div className='info-page__feature d-flex align-items-center'>
            <span className={color}>{props.listText}</span>
            <div>
                <h5 className='info-page__info'>{props.title}</h5>
                <p className='info-page__info'>{props.children}</p>
            </div>
        </div>
    );
};

export default InfoFeature;