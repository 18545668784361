import {apiAddress} from "./APILink";

const getCEOText = (dispatch, subcategory_id) => {
    if (!subcategory_id) subcategory_id = 'NULL';
    dispatch({type: 'SetLoadingCEO'});
    fetch(apiAddress + 'api/scripts/CEO.php?location=' + subcategory_id, {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadCEO', payload: data});
        });
}

export {getCEOText};