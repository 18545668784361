import {useTranslation} from "react-i18next";

import HollowButton from "../../components/UI/Buttons/HollowButton";
import Container from "../../components/container/Container";
import Ball from "../../components/Ball";

import './PageNotFound.css';

const PageNotFound = () => {
    const { t } = useTranslation();

    return (
        <main className='p-relative'>
            <Container>
                <div className='page-not-found d-flex justify-content-center align-items-center'>
                    <div className='text-center'>
                        <h1 className='secondary-text page-not-found__title'>404</h1>
                        <p className='gray-text page-not-found__info'>{t('pageNotFound.subheader')}</p>
                        <p className='white-text page-not-found__description'>{t('pageNotFound.header')}</p>
                        <HollowButton primary text={t('pageNotFound.button')} to='/' />
                    </div>
                </div>
                <Ball
                    background='#FE0098'
                    bottom='0'
                    right={0}
                />
                <Ball
                    background='#FE7A00'
                    bottom='0'
                    left={0}
                />
                <Ball
                    background='#E4B948'
                    top={0}
                    right={0}
                />
            </Container>
        </main>
    );
};

export default PageNotFound;