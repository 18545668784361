import './OrderDetailLine.css';

const OrderDetailLine = (props) => {
    let titleContent = <h3 className='white-text order-line__title'>{props.title}</h3>;

    if (props.quantity) {
        titleContent = (
            <div className='d-flex align-items-center'>
                <span className='white-text order-line__quantity'>X{props.quantity}</span>
                <h3 className='gray-text order-line__title'>{props.title}</h3>
            </div>
        );
    }

    return (
        <div className='order-line d-flex justify-content-between align-items-center'>
            {titleContent}
            <span className='secondary-text order-line__price'>{props.price}</span>
        </div>
    );
};

export default OrderDetailLine;