import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

import LoginProvider from "./store/LoginProvider";
import CartProvider from "./store/CartProvider";
import App from './App';

import './index.css';
import './colors.css'
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <LoginProvider>
        <CartProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </CartProvider>
    </LoginProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
