const CartIcon = (props) => {
    const width = props.width ?? 17;
    const height = props.height ?? 12;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width={width} height={height} viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" style={{fill: '#CA9D2A'}}>
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><path d="M689.5,905c0,49.7,40.3,90,90,90s90-40.3,90-90c0-49.7-40.3-90-90-90S689.5,855.3,689.5,905z"/><path d="M257.5,905c0,49.7,40.3,90,90,90c49.7,0,90-40.3,90-90c0-49.7-40.3-90-90-90C297.8,815,257.5,855.3,257.5,905z"/><path d="M937,189.5H280l-18-126C257.5,41,244,23,226,14c-13.5-4.5-22.5-9-36-9H64C32.5,5,10,36.5,10,68l0,0c0,36,22.5,63,54,63h76.5c0,0,18,121.5,18,126l63,445.5c4.5,27,27,49.5,54,49.5h567c27,0,49.5-22.5,54-49.5l90-445.5C1000,225.5,973,189.5,937,189.5z"/></g>
        </svg>
    );
};

export default CartIcon;