import './Checkbox.css';

const Checkbox = (props) => {
    let classes = 'checkbox__container';

    if (props.className) classes += ' ' + props.className;
    if (props.dark) classes += ' dark-text';
    else classes += ' gray-text'

    return (
        <label className={classes}>
            <input type="checkbox" onClick={props.onClick} onChange={props.onChange} />
            { props.text }
        </label>
    );
}

export default Checkbox;