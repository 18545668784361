import {apiAddress} from "./APILink";

const sendCallbackForm = (FLName, email, message) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            FLName, email, message
        }),
    };
    return fetch(apiAddress + 'api/scripts/CallbackForm.php', requestOptions);
};

export {sendCallbackForm};