const TriangleClosed = (props) => {
    let width = 14;
    let height = 11;

    if (props.width) width = props.width;
    if (props.height) height = props.height;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 14" fill="none">
            <path d="M9.73501 6.16533C10.334 6.56057 10.334 7.43943 9.73501 7.83467L2.05074 12.905C1.38589 13.3437 0.5 12.8668 0.5 12.0703L0.500001 1.92969C0.500001 1.13315 1.38589 0.656329 2.05074 1.09502L9.73501 6.16533Z" fill="#CA9D2A"/>
        </svg>
    );
}

export default TriangleClosed;