import OrderDetailLine from "./OrderDetailLine";
import Line from "../../components/UI/Line";
import {priceProcessing} from "../../helpers/Processors";

import './OrderDetails.css';
import {useTranslation} from "react-i18next";

const OrderDetails = (props) => {
    const { t, i18n } = useTranslation();
    const totalAmount = priceProcessing(props.totalAmount, true);
    const orderDetails = props.orderContent.map((order) => {
        return <OrderDetailLine
                    key={order.id}
                    id={order.id}
                    quantity={order.amount}
                    title={order['name_' + i18n.resolvedLanguage]}
                    price={order.price}
                />
    });

    return (
        <div className='order-details'>
            <OrderDetailLine title={t('profilePage.orderSum')} price={totalAmount} />
            <Line dark />
            {orderDetails}
            <Line dark />
        </div>
    );
};

export default OrderDetails;