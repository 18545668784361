import {Link} from "react-router-dom";

 

import './TopBrand.css';

const TopBrand = (props) => {
    return (
        
            <Link to={'/shop/brand/' + props.url} className='top-brand'>
                <span className='top-brand'>
                    {props.logo && <img src={props.logo} alt='top brand logo' />}
                    {props.svgLogo}
                </span>
            </Link>
        
    );
};

export default TopBrand;