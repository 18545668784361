import {useState} from "react";

import TriangleOpened from "../../assets/icons/TriangleOpened";
import TriangleClosed from "../../assets/icons/TriangleClosed";
import SlideDown from "react-slidedown/lib/slidedown";
import OrderDetails from "./OrderDetails";

import './Order.css';
import {useTranslation} from "react-i18next";

const Order = (props) => {
    const { t, i18n } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const openOrderHandler = () => {
        setIsOpened(prevState => !prevState);
    };

    const dateData = new Date(props.date);
    const months = {
        ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        ro: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']
    };

    const date = dateData.getDate() + ' ' + months[i18n.resolvedLanguage][dateData.getMonth()] + ' ' + dateData.getFullYear();

    return (
        <article className='d-flex order' style={{cursor: 'pointer'}} onClick={openOrderHandler}>
            <div className='align-self-top order__control'>
                {isOpened ? <TriangleOpened width={25} height={20} /> : <TriangleClosed width={25} height={20} /> }
            </div>
            <div className='order-body'>
                <div className='order-body__shortInfo d-flex align-items-center justify-content-between'>
                    <span className='order__orderNo grey-text'>Order #{props.id}</span>
                    <span className='order__date white-text'>{date}</span>
                    <span className='order__action primary-text'>{t('profilePage.boughtItemsHeader')}</span>
                </div>
                <SlideDown className='w-100'>
                    { isOpened ? <OrderDetails totalAmount={props.totalAmount} orderContent={props.orderContent} /> : null }
                </SlideDown>
            </div>
        </article>
    );
};

export default Order;