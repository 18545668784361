import './SidebarContainer.css';

const SidebarContainer = (props) => {
    let classes = 'sidebar-container';

    if (props.className) classes += ' ' + props.className;

    return (
        <div className={classes}>
            {props.children}
        </div>
    );
};

export default SidebarContainer;