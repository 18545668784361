import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            ru: {
                translation: {
                    add_to_card_text: 'Купить',
                    promo_want: 'Хотите скидку?',
                    nu_este_stock: 'Нет в наличии',
                    navigation: {
                        home: 'домой',
                        shop: 'магазин',
                        contacts: 'контакты',
                        info: 'информация',
                        profile: 'профиль',
                        login: 'войти',
                        register: 'регистрация',
                        burgerMenuLoginLabel: 'Зарегестрируйтесь / Войдите и получайте накопительные скидки и бонусы!',
                    },
                    cart: {
                        breadcrumbs: {
                            shop: 'Магазин',
                            cart: 'Корзина'
                        },
                        widgetHeader: 'Корзина',
                        delivery: 'Доставка',
                        emptyCartMessage: 'Корзина пуста',
                        continueShoppingButton: 'Продолжить покупки',
                        makeOrderButton: 'Оформить заказ',
                        sidebarHeader: 'Всего',
                        totalSum: 'Итого',
                        message: 'Минимальная сумма заказа 200 леев',
                        promoPlaceholder: 'Ввести промокод',
                        globalDiscount: 'Скидка',
                        couponButton: 'Применить'
                    },
                    mainPage: {
                        featuresBlock: {
                            header: 'Покупайте у нас!',
                            bestPriceTitle: 'Лучшие цены',
                            bestPriceText: 'Самые лучшие цены в Молдове! Убедитесь сами!',
                            officialDistributorTitle: 'Официальный дистрибьютор',
                            officialDistributorText: 'Официальный дистрибьютор премиальных табачных брендов.',
                            fastDeliveryTitle: 'Быстрая доставка',
                            fastDeliveryText: 'При заказе до 17:00 мы доставим  ваш заказ в тот же день!',
                            returnProductTitle: 'Возврат товаров',
                            returnProductText: 'Не подошел товар? Не проблема. Возврат товара курьеру бесплатен!'
                        }
                    },
                    categorySelectionPage: {
                        mobileHeader: 'Категории',
                        searchbarPlaceholder: 'Поиск товаров'
                    },
                    categoryBrowserPage: {
                        breadcrumbs: {
                            categories: 'Категории'
                        },
                        filters: {
                            header: 'Фильтры',
                            price: 'Цена',
                            applyButton: 'Готово',
                            openFiltersButton: 'фильтры'
                        }
                    },
                    productPage: {
                        breadcrumbs: {
                            categories: 'Категории'
                        },
                        controls: {
                            quantity: 'Количество',
                            buyButton: 'Купить',
                            buyOneClick: 'Купить в 1 клик',
                            discount: 'Хотите скидку?'
                        }
                    },
                    contactPage: {
                        header: 'Контакты',
                        workHoursText: 'Часы работы:',
                        workHoursInfo: 'Понедельник-Пятница: с 10:00 до 18:00',
                        workHoursInfo2: 'Суббота: с 10:00 до 18:00',
                        location1: 'UNIC, Подземный переход,',
                        location2: 'ELAT, 1-й этаж, бутик 19,',
                        location3: 'ATRIUM, отдельный вход с ул. Д. Кантемир,',
                        from: 'c', // время
                        to: 'до',
                        forms: {
                            name: 'Ваше имя',
                            message: 'Письмо',
                            sendButton: 'Отправить',
                            somethingWrong: 'Упс, что-то пошло не так, пожалуйста, попробуйте позже!'
                        }
                    },
                    infoPage: {
                        header: 'Информация',
                        modules: {
                            module1: {
                                title: 'о магазине',
                                paragraph1: 'Torsedor – онлайн магазин табачных изделий.  Мы внимательно следим за трендами табачного рынка, поэтому всё, что заслуживает внимания витолье появляется в онлайн-магазине. В том числе и редкие и высококачественная продукция, которой нет больше нигде в Молдове. Мы настолько уверены в качестве продукции, что оформим возврат, если вы окажетесь им недовольны.\n' +
                                    'Мы работаем только с официальными дистрибьюторами топовых брендов Te Amo, Davidoff, Casa Turrent, Gizeh, Zippo, Mac Baren, Stanley, Clubmaster  и многими другими. Все бренды, которые представлены в Torsedor можно найти на нашем сайте.',
                                paragraph2: 'Torsedor это место благодаря которому курение превращается в настоящее искусство. ',
                                paragraph3: 'Перечень наших товаров постоянно пополняется новинками, среди которых каждый сможет найти что-то интересное.\n' +
                                    'Кроме того, в Torsedor вы можете приобрести сертификат на сумму 500, 1000 и 2000 леев, который станет отличным подарком для настоящего ценителя качественных табачных изделий.',
                                paragraph4: 'Наш огромный и разнообразный ассортимент представлен на нашем сайте и вы с легкостью сможете сделать заказ в любой регион Молдовы. Наши онлайн консультанты будут на связи в течении всего рабочего дня и с удовольствием смогут вам помочь. Уверены, Вам захочется вернуться к нам ещё не один раз.'
                            },
                            module2: {
                                title: 'накопительные скидки',
                                addressing: 'Уважаемые клиенты,',
                                paragraph1: 'В нашем онлайн магазине Torsedor  действует система накопительных скидок, с учетом всех ваших предыдущих заказов. Чем больше сумма покупок, тем больше размер скидок! Данная система накопительных скидок действительна только для Регистрированных пользователей.  Если Вы еще не зарегистрированы, пожалуйста посетите страницу регистрации, если Вы уже зарегистрировались, пожалуйста зайдите в Ваш личный кабинет через страницу авторизации\n' +
                                    'Покупайте больше и экономьте больше!',
                                sumText: 'Сумма покупок:',
                                currency: 'лей',
                                paragraph2: 'При первом заказе на сайте скидка не учитывается, но при втором и последующих заказах, сумма первого заказа учитывается для расчета накопительной скидки.'
                            },
                            module3: {
                                title: 'покупка и оплата',
                                header1: 'КАК ЗАКАЗАТЬ?',
                                text1: 'Вы можете оформить заказ на сайте, по телефону или в социальных сетях.',
                                header2_1: 'В СЛУЧАЕ БРАКОВАННОЙ ЭЛЕКТРОННОЙ СИГАРЕТЫ, МЫ ОБМЕНИВАЕМ ЕЕ В ТЕЧЕНИЕ 48 ЧАСОВ С МОМЕНТА ДОСТАВКИ, ДЛЯ ЭТОГО ВАМ НЕОБХОДИМО ПРИСЛАТЬ ВИДЕО С ДОКАЗАТЕЛЬСТВОМ НЕИСПРАВНОСТИ.',
                                text2_1: '',
                                header2: 'ЕСТЬ МИНИМАЛЬНАЯ СУММА ЗАКАЗА?',
                                text2: 'При заказе на сайте минимальная сумма вашего заказа должна быть 250 лей от чего ваша доставка становится бесплатной, до 250 лей доставка будет стоить 50 лей (Центр, Ботаника, Чеканы, Телецентр, Скулянка, Старая Почта, Рышкановка, Буюканы). В случае необходимости доставки к определённому времени минимальная сумма заказа 500 лей. Доставка за пределы Кишинева (до 10 км от города): стоимость – 60л',
                                header3: 'СО МНОЙ СВЯЖЕТСЯ МЕНЕДЖЕР?',
                                text3: 'После того, как вы оформите заказ на сайте, в течение 30 минут с вами свяжется менеджер. У него можно уточнить детали заказа.',
                                header4: 'ЗАКАЗ НУЖНО ОПЛАЧИВАТЬ СРАЗУ?',
                                text4: 'Нет, вы оплачиваете заказ при получении. Мы принимаем и наличные, и карты.',
                                header5: 'УСЛОВИЯ ПРОДАЖИ',
                                text5: 'Табачный магазин torsedor.md не продает и не доставляет табачные изделия несовершеннолетним; Пожалуйста, ознакомьтесь с правилами работы настоящего сайта.',
                                header6: 'ДОСТАВКА ПО РЕСПУБЛИКЕ МОЛДОВА ОСУЩЕСТВЛЯЕТСЯ ЧЕРЕЗ КУРЬЕРСКУЮ СЛУЖБУ НОВАЯ ПОЧТА',
                                text6: 'Оплата за товар и доставку производится непосредственно курьеру Новой Почты наличными. Стоимость доставки в среднем 60 л при весе заказа до 2 кг. Сроки доставки заказов по республике – до 3 дней (оговариваются с оператором). Доставка выполняется по расписанию и маршруту следования грузовой машины компании (уточняется у оператора) и в том случае, если вы проживаете в пределах указанного в маршруте населённого пункта. При отправке вашего груза мы отправляем вам ваш трек номер по которому вы можете следить где находится ваша посылка на сайте: https://novaposhta.md '
                            },
                            module4: {
                                title: 'для оптовых покупателей',
                                text1: 'Наша компания нацелена на построение долгих и плодотворных отношений с нашими покупателями по всей Молдове. Мы гарантируем индивидуальный подход к каждому партнеру и предлагаем наиболее выгодные для него условия сотрудничества.',
                                header1: 'Кто может стать оптовым покупателем?',
                                text2: 'Мы предложим Вам выгодные цены и условия сотрудничества, если вы:',
                                list1: 'Юридическое лицо или индивидуальный предприниматель\n' +
                                    'Оптовая или розничная торговая база\n' +
                                    'Владелец крупного или мелкого магазина (торговой точки)\n' +
                                    'Региональный интернет-магазин\n' +
                                    'Организатор совместных покупок',
                                text3: 'Если вы не нашли себя в этом списке, но условия оптовых закупок вам полностью подходят, то пишите - нам есть, что вам предложить!',
                                text4: 'Условия работы с оптовыми покупателями',
                                list2: 'Все товары отгружаются штучно.\n' +
                                    'Отправка заказа осуществляется после 50 или 100% предоплаты, в зависимости от договоренности.\n' +
                                    'Доставка оптовых заказов производится надежными транспортными средствами с нашей стороны ( если это город Кишинев), если по Молдове то доставка обсуждается.\n' +
                                    'Вся поставляемая продукция сертифицирована, при поставке заказа покупатель получает копии сертификатов соответствия, качества продукции, а также полный пакет документов, подготовленных для Вас сотрудниками нашей компании.',
                                header2: 'Как стать  нашим оптовым покупателем ?',
                                text5: 'Чтобы приобретать нашу  продукцию по оптовым ценам, Вам необходимо подать заявку на регистрацию оптового покупателя. Для этого отправьте на наш электронный почтовый ящик torsedoroffice@gmail.com письмо по форме:',
                                list3: 'ФИО\n' +
                                    'Наименование организации или индивидуального предпринимателя.\n' +
                                    'Карточка реквизитов.\n' +
                                    'Ваш город или населенный пункт (обязательно).\n' +
                                    'Контактный номер телефона и адрес для связи.\n' +
                                    'Ваш род деятельности и предполагаемые каналы сбыта.',
                                text6: 'После рассмотрения заявки (как правило, на рассмотрение уходит 1 день), мы свяжемся с вами и вышлем коммерческое предложение.',
                                text7: 'Мы открыты любым предложениям и готовы к сотрудничеству. Отправьте свою заявку прямо сейчас!',
                            },
                            module5: 'контакты',
                        },
                        advantages: {
                            header: 'Наши сильные стороны',
                            quality: 'КАЧЕСТВО',
                            qualityText: 'Качество наших табаков и сигар подтверждается сертификатами соответствия и качества. Мы умеем хранить сигары и табаки - для этого у нас есть специальные хьюмидоры.',
                            speed: 'СКОРОСТЬ',
                            speedText: 'Оформление заказа на нашем сайте будет быстрым, легким и приятным. Менеджеры связываются с Вами в течение 10 минут.',
                            exclusivity: 'ЭКСКЛЮЗИВНОСТЬ',
                            exclusivityText: 'Вы давно ищете какой-то определенный вид сигар или табака? Мы будем рады предложить вам большой ассортимент самой разнообразной табачной продукции!'
                        },
                        QA: {
                            header: 'Покупка и оплата',
                            q1: 'КАК ЗАКАЗАТЬ?',
                            q1Text: 'Вы можете оформить заказ на сайте, по телефону или непосредственно в магазине.',
                            q2: 'ЕСТЬ МИНИМАЛЬНАЯ СУММА ЗАКАЗА?',
                            q2Text: 'При заказе на сайте минимальная сумма вашего заказа должна быть 250 лей от чего ваша доставка становится бесплатной, до 250 лей доставка будет стоить 30 лей.',
                            q3: 'СО МНОЙ СВЯЖЕТСЯ МЕНЕДЖЕР?',
                            q3Text: 'После того, как вы оформите заказ на сайте, в течение 30 минут с вами свяжется менеджер. У него можно уточнить детали заказа.',
                            q4: 'ЗАКАЗ НУЖНО ОПЛАЧИВАТЬ СРАЗУ?',
                            q4Text: 'Нет, вы оплачиваете заказ при получении. Мы принимаем и наличные, и карты.',
                            conditions: 'УСЛОВИЯ ПРОДАЖИ',
                            conditionsText: 'Табачный магазин torsedor.md не продает и не доставляет табачные изделия несовершеннолетним; Пожалуйста, ознакомьтесь с правилами работы настоящего сайта.',
                            q5: 'ДОСТАВКА ПО РЕСПУБЛИКЕ МОЛДОВА ОСУЩЕСТВЛЯЕТСЯ ЧЕРЕЗ КУРЬЕРСКУЮ СЛУЖБУ НОВАЯ ПОЧТА',
                            q5text: 'Оплата за товар и доставку производится непосредственно курьеру Новой Почты наличными. \n Стоимость доставки в среднем 60 л при весе заказа до 2 кг. Сроки доставки заказов по республике – до 3 дней (оговариваются с оператором). \n\n Доставка выполняется по расписанию и маршруту следования грузовой машины компании (уточняется у оператора) и в том случае, если вы проживаете в пределах указанного в маршруте населённого пункта. При отправке вашего груза мы отправляем вам ваш трек номер по которому вы можете следить где находится ваша посылка на сайте: https://novaposhta.md (https://novaposhta.md/). При использовании промокода если сумма заказа меньше 250 л расчитывать доставку 50 лей '
                        },
                        contacts: {
                            header: 'Свяжитесь с нами!',
                            phonesHeader: 'Телефоны',
                            locationHeader: 'Магазины'
                        }
                    },
                    loginForms: {
                        error: {
                            emptyFields: 'Пожалуйста, заполните все поля!',
                            emailValidation: 'Введите валидный адрес электронной почты!',
                            emailIsUsed: 'Введенный адрес электронной почты уже используется!',
                            passwordLength: 'Пароль должен содержать минимум 8 символов',
                            passwordMismatch: 'Введенные пароли не совпадают!',
                            login: 'Вы ввели неверную электронную почту или пароль!',
                            link: 'Ссылка больше недействительна',
                            phoneFormat: 'Неправильной формат телефона +373 xxx xx xxx!'
                        },
                        loginHeader: 'Войти',
                        backButton: 'назад',
                        name: 'Имя',
                        surname: 'Фамилия',
                        phoneNumber: 'Телефон (+373)',
                        newsletterSubscribe: 'Подписаться на рассылку',
                        password: 'Пароль',
                        newPassword: 'Новый пароль',
                        repeatPassword: 'Повторить пароль',
                        forgotPasswordLink: 'Забыли пароль?',
                        registrationLink: 'Создать аккаунт',
                        loginButton: 'Войти',

                        restoreHeader: 'Восстановить пароль',
                        restoreText: 'Введите адрес электронной почты Вашей учетной записи. Нажмите кнопку Продолжить, чтобы получить пароль по электронной почте.',
                        restoreButton: 'Восстановить пароль',
                        restoreInfo: 'Вы получите письмо на почту с уведомлением об восстнавлении пароля',

                        changePasswordHeader: 'Смена пароля',
                        changePasswordButton: 'Сменить пароль',

                        registrationHeader: 'Регистрация',
                        registrationButton: 'Создать аккаунт',
                        registrationAgreement1: 'Нажимая “Создать аккаунт” я соглашаюсь с',
                        registrationAgreement2: 'правилами и условиями',
                        registrationAgreement3: 'использования сайта.'
                    },
                    profilePage: {
                        breadcrumbs: {
                            profile: 'Личный кабинет'
                        },
                        greeting: 'Привет, ',
                        totalOrdersSum: 'Общая сумма заказов:',
                        orderHistoryHeader: 'История заказов',
                        logoutButton: 'Выйти',

                        discountHeader: 'Система скидов',
                        discount: 'скидка', // скидка 7%
                        discountInfo1: 'Система накопительных скидок, больше информации вы можете узнать связавшись с нами, или получив',
                        discountInfo2: 'информацию здесь',
                        maxDiscount: 'Максимальная скидка',

                        boughtItemsHeader: 'Купленные товары',
                        orderSum: 'Сумма заказа: '
                    },
                    pageNotFound: {
                        header: 'Мы не можем найти эту страницу',
                        subheader: 'Ой, страница не найдена!',
                        button: 'Главная'
                    },
                    checkoutPage: {
                        breadcrumbs: {
                            checkout: 'Оформление заказа'
                        },
                        agreeCheckbox1: 'Я изучил и согласен с',
                        agreeCheckbox2: 'пользовательским соглашением',
                        expressDelivery: 'Экспресс доставка',
                        deliverySidebarHeader: 'Доставка',
                        forms: {
                            city: 'Город',
                            address: 'Адрес',
                            FLName: 'ФИО',
                            phoneNumber: 'Номер телефона +373',
                            comment: 'Комментарий',
                            cashRadio: 'Оплата наличными',
                            cardRadio: 'Оплата картой по  прибытию курьера',
                            noCallCheckbox: 'Не перезванивать при оформлении заказа'
                        },
                        error: 'Товары из вашей корзины больше недоступны!'
                    },
                    successPage: {
                        header: 'Спасибо за покупку!',
                        message1: 'Заказ обрабатывается',
                        message2: 'Мы отправили инвойс на ваш почтовый адрес!',
                        mainPageButton: 'Главная',
                        profileButton: 'Кабинет'
                    },
                    modals: {
                        oneClick: {
                            header: 'Купить товар в один клик. Заполните форму и менеджер перезвонит Вам!',
                            FLNamePlaceholder: 'ФИО',
                            phoneNumberPlaceholder: 'Телефон (+373)',
                            buyButton: 'Купить'
                        },
                        ageWarning: {
                            warningMessage: 'Посещение нашего сайта допускается только с +18 лет',
                            acceptButton: 'Да, мне исполнилось 18 лет',
                            declineButton: 'Нет, мне не исполнилось 18 лет',
                            warningInfo: 'Нажав «Да», вы подтверждаете, что достигли установленного законом возраста для приобретения и потребления табачных изделий.'
                        },
                        cookies: {
                            text: 'Мы используем файлы cookie для предоставления Вам больше возможностей использования сайта.',
                            infoButton: 'больше информации',
                            acceptButton: 'Принять'
                        },
                        timer: {
                            message: {
                                success: 'Ваш промокод был отправлен на указанную почту',
                                emailIsUsed: 'Указанная почта уже получила скидку'
                            },
                            headerPrimary: 'Дарим Вам скидочный',
                            headerSecondary: 'купон на 10% на любую продукцию!',
                            timerHeader: 'Персональный купон действителен',
                            formHeader: 'Оставьте email и получите купон прямо сейчас!',
                            emailLabel: 'Ваш email',
                            actionButton: 'Получить купон',
                            floatButton: 'Хотите скидку?'
                        }
                    },
                    productCard: {
                        buyButton: 'Купить'
                    },
                    topBrands: {
                        header: 'Наши топ бренды'
                    },
                    CEOBlock: {
                        header: 'Премиальная продукция',
                        subheader: 'Лучшая продукция мировых табачных брендов впервые в Молдове!',
                        signature: 'Команда Torsedor.md'
                    },
                    InstagramBlock: {
                        header: 'Подписывайтесь на нас в Инстаграм!',
                        subheader: 'Посты, горячие предложения, розыгрыши, новые товары, и консультирование в директ!',
                        subscribeButton: 'подписаться'
                    },
                    footer: {
                        payDeliveryLink: 'Оплата и Доставка',
                        aboutLink: 'О компании',
                        contactsLink: 'Контакты',
                        shopLink: 'Магазин',
                        partnersInfoLink: 'Партнерам',
                        restrictionText: 'Această resursă este destinată numai adulților. Ministerul Sănătății Republicii Moldova avertizează că fumatul dăunează sănătății tale.'
                    },
                    carousels: {
                        newCarouselHeader: 'Новое',
                        popularCarouselHeader: 'Популярное',
                        discountCarouselHeader: '%Акции',
                    }
                }
            },
            ro: {
                translation: {
                    add_to_card_text: 'Cumpără',
                    promo_want: 'Doriți reducere?',
                    nu_este_stock: 'Nu este in stoc',
                    navigation: {
                        home: 'acasă',
                        shop: 'produse',
                        contacts: 'contacte',
                        info: 'informație',
                        profile: 'profilul',
                        login: 'autentificare',
                        register: 'înregistrare',
                        burgerMenuLoginLabel: 'înregistrați-vă/ autentificați-vă și primiți bonus-uri și oferte!',
                    },
                    cart: {
                        breadcrumbs: {
                            shop: 'Produse',
                            cart: 'Coș'
                        },
                        widgetHeader: 'Coș',
                        delivery: 'Livrare',
                        emptyCartMessage: 'Coșul tău este în prezent gol.',
                        continueShoppingButton: 'Înapoi',
                        makeOrderButton: 'finalizares comenzii',
                        sidebarHeader: 'Total', // Всего
                        totalSum: 'Total', // Итого
                        message: 'Suma minimală a comenzii este 200 MDL',
                        promoPlaceholder: 'Întroduceți promocodul',
                        globalDiscount: 'Reducere',
                        couponButton: 'Foloseste acum'
                    },
                    mainPage: {
                        featuresBlock: {
                            header: 'Cumpărați la noi!',
                            bestPriceTitle: 'Prețuri cei mai buni!',
                            bestPriceText: 'Cele mai bune preturi din Moldova! Convinge-te singur!',
                            officialDistributorTitle: 'Distribuitor oficial',
                            officialDistributorText: 'Distribuitor oficial al brand-uri premium de tutun.',
                            fastDeliveryTitle: 'Livrare rapidă',
                            fastDeliveryText: 'Dacă comandați înainte de ora 17:00, vă vom livra comanda în aceeași zi!',
                            returnProductTitle: 'Returnarea produselor',
                            returnProductText: 'Produsul nu s-a potrivit? Nici o problemă. Returul bunurilor la curier este gratuit!'
                        }
                    },
                    categorySelectionPage: {
                        mobileHeader: 'Categorii',
                        searchbarPlaceholder: 'Cautare produs'
                    },
                    categoryBrowserPage: {
                        breadcrumbs: {
                            categories: 'Categorii'
                        },
                        filters: {
                            header: 'Filtre',
                            price: 'Preț',
                            applyButton: 'Gata',
                            openFiltersButton: 'filtre'
                        }
                    },
                    productPage: {
                        breadcrumbs: {
                            categories: 'Categorii'
                        },
                        controls: {
                            quantity: 'Cantitate',
                            buyButton: 'Cumpără',
                            buyOneClick: 'Cumpărați cu 1 clic',
                            discount: 'Vrei o reducere?'
                        }
                    },
                    contactPage: {
                        header: 'Contacte',
                        workHoursText: 'Ore de lucru:',
                        workHoursInfo: 'Luni - Vineri: de la 10:00 la 18:00',
                        workHoursInfo2: 'Sâmbătă: de la 10:00 la 18:00',
                        location1: 'UNIC, pasaj subteran,',
                        location2: 'ELAT, etajul 1, b.19,',
                        location3: 'ATRIUM, intrare de strada. D. Cantemir,',
                        from: 'de la', // время
                        to: 'pâna la',
                        forms: {
                            name: 'Numele',
                            message: 'Mesaj',
                            sendButton: 'Trimite',
                            somethingWrong: 'Oops, incercati mai tarziu'
                        }
                    },
                    infoPage: {
                        header: 'Info',
                        modules: {
                            module1: {
                                title: 'despre magazin',
                                paragraph1: 'Torsedor- magazinul online ce conține produse din tutun. Urmărim îndeaproape tendițile pieței tutunului, deci tot ceea , ce merită atenție, apare  în cele din urmă în magazinul nostru online. Inclusiv produse rare și de înaltă calitate , care nu se găsește in Moldova. Suntem atît de siguri in calitatea produselor , ca vom emite o rambursare , în caz că veți fi nemulțumit. Noi lucrăm doar cu distribuitori oficiali ai mărcilor de top Te Amo, Davidoff, Casa Turrent, Gizeh, Zippo, Mac Baren, Stanley, Clubmaster și mulți alții. Toate mărcile care sunt reprezentate pe Torsedor, le puteți găsi pe site-ul nostru',
                                paragraph2: 'Torsedor este locul datorită caruia fumatul devine o adevarată artă.',
                                paragraph3: 'Lista produselor noastre este actualizată constant cu produse noi, printre care oricine poate găsi ceva pe placul său. În plus la toate , pe Torsedor puteți achiziționa certificat în valoare de 500, 1000 și 2000 lei, care va deveni un cadou minunat pentru un adevărat cunoscător al produselor din tutun de calitate.',
                                paragraph4: 'Asortimentul nostru uriaș și variat este prezentat pe site-ul nostru și puteți plasa cu ușurință o comandă în orice regiune a Moldovei. Consultații noștri online vor fi în contact pe tot parcursul zilei de lucru și vor fi bucuroși sa va ajute. Sunt sigur că vei dori sa te întorci la noi , de mai multe ori!'
                            },
                            module2: {
                                title: 'reduceri cumulate',
                                addressing: 'Stimați clienti,',
                                paragraph1: 'In magazinul nostru online TORCEDOR , este valabilă sistema de reduceri acumulate , si luăm in considerare toate comenzile  precedente. Cu cît e mai mare suma cumpărăturilor, cu atît e mai mare procentul reducerii! Sistema dată,  de acumularea reducerilor,  este valabilă doar pentru persoanele înregistrate . Dacă nu sînteți înca înregistrați , vă rugăm să accesați pagina cu ÎNREGISTRARE , dacă sunteți înregistrați , vă rugăm să intrați în cabinetul personal prin pagina de AUTORIZARE. Cumpărați mai mult si economisiți mai mult!',
                                sumText: 'Cantitatea de cumpărături:',
                                currency: 'lei',
                                paragraph2: 'La prima comandă online , reducerea nu va fi aplicată, dar pentru a doua și următoarele comenzi se ia în considerare suma primei comenzi pentru a calcula reducerea cumulativă.'
                            },
                            module3: {
                                title: 'cumpărare și plată',
                                header1: 'Cum să comand?',
                                text1: 'Puteți plasa o comandă pe site, prin numărul de telefon sau în rețelele de socializare.',
                                header2_1: 'ÎN CAZUL UNEI ȚIGĂRI ELECTRONICE DEFECTE, O SCHIMBĂM ÎN TERMEN DE 48 DE ORE DE LA DATA LIVRĂRII, PENTRU ACEASTA TREBUIE SĂ TRIMITEȚI UN VIDEO CU DOVADA DEFECȚIUNI.',
                                text2_1: '',
                                header2: 'Există o sumă minimă de comandă?',
                                text2: 'La comanda pe site, suma minimă a comenzii tale ar trebui sa fie de 250 lei, din care livrarea ta devine gratuită, pana la 250 lei livrarea va costa 50 lei (Centru, Botanica, Ciocana, Telecentru, Sculeanca, Posta Veche, Riscani, Buiucani). În cazul in care este necesară livrarea intr-un anumit timp, valoarea minimă a comenzii este de 500 lei. Livrarea în afara Chișinăului ( pânâ la 10 km de oraș) : cost – 60 lei. Timp de livrare în zilele lucrătoare până la ora 19:00',
                                header3: 'Managerul ma va contacta?',
                                text3: 'După ce plasați o comandă pe site, vă va contacta managerul în 30 de minute. Cu ajutorul managerului, puteți verifica detaliile comenzii.',
                                header4: 'Trebu sa achit comandq mea imediat?',
                                text4: 'Trebu sa achit comandq mea imediat? Nu, achitați comanda la primire. Acceptam atât numerar, cât și carduri.',
                                header5: 'Condiții de vînzare',
                                text5: 'Magazinul de tutun TORCEDOR  nu vinde și nu livrează produsele minorilor. Vă rugăm să citiți regulile acestui site.',
                                header6: 'Plata mărfurilor și livrarea se face direct la curierul Nova Poshta în numerar',
                                text6: 'Costul mediu de livrare este de 60 de lei cu o greutate a comenzii de până la 2 kg. Termen de livrare pentru comenzile din tara - pana la 3 zile (negociati cu operatorul). Livrarea se efectuează conform orarului și traseului camionului companiei (confirmare cu operatorul) și dacă locuiți în localitatea indicată pe traseu. Când trimiteți comanda, vă trimitem numărul comenzii prin care puteți urmări unde se află coletul dvs. online, pe site-ul: https://novaposhta.md'
                            },
                            module4: {
                                title: 'pentru cumpărătorii angro',
                                text1: 'Compania noastră pune accentul pentru a construi o relație rodnică cu cumpărătorii noștri din toata Moldova. Noi garantăm o abordare individuală, pentru fiecare partener aparte , si vă propunem cele mai avantajoase condiții pentru cooperare.',
                                header1: 'Cine poate fi un cumpărător angro?',
                                text2: 'Noi vă oferim cele mai avantajoase prețuri si condiții pentru cooperare, daca voi: ',
                                list1: 'Persoana juridica sau antreprenor individual\n' +
                                    'Bază de comerț angro sau per unitate \n' +
                                    'Proprietar al unui scor mare sau mic (punct comercial) \n' +
                                    'Internet magazin regional \n' +
                                    'Organizator al achizițiilor comune',
                                text3: 'Dacă nu v-ați regăsit in lista dată , dar condițiile achizițiilor angro complet vi se potrivesc, scrieți-ne noua- noi avem, ce să vă propunem! ',
                                text4: 'Condițiile de lucru cu cumpărătorii angro',
                                list2: 'Toate produsele se descarcă  bucata cu bucata \n' +
                                    'Trimiterea unei comenzi se execută după ce se achită 50% sau 100% plata anticipată, in dependență de acord.\n' +
                                    'Livrarea comenzilor angro sunt produse de un vehicul de încredere din partea noastră ( daca este orașul Chișinău ), prin Moldova livrarea de discută.\n' +
                                    'Toata producția furnizată are certificat, la livrarea comenzii, cumpărătorul primește copii ale certificatului de conformitate, calitatea produsului, precum și pachetul complet de documente, pregătit de angajații companiei noastre  pentru d-voastră.',
                                header2: 'Cum să devii cumpărătorul nostru angro? ',
                                text5: 'Pentru a cumpăra produsele noastre la prețuri angro, este necesar să completați cererea cumpărătorului angro. Pentru aceasta trimite-ne la adresa de e-mail  torsedoroffice@gmail.com o scrisoare in forma: ',
                                list3: 'Numele si prenumele complet \n' +
                                    'Numele companiei sau întreprinderii individuale \n' +
                                    'Cartela de recvizite\n' +
                                    'Orașul sau localitatea voastră (necesar).\n' +
                                    'Numărul de contact si adresa (pentru a vă contacta).\n' +
                                    'Ocupația și canalele de distribuție vizate ale d-vstră. ',
                                text6: 'După aplicare (conform regulilor, pentru a lua o decizie avem nevoie de o zi), va vom contacta noi și o să vă trimitem oferta comercială.',
                                text7: 'Suntem deschiși pentru orice propunere și suntem gata să cooperam. Trimite-ne cererea ta chiar acum!'
                            },
                            module5: 'contacte',
                        },
                        advantages: {
                            header: 'Punctele noastre forte',
                            quality: 'Calitatea',
                            qualityText: 'Calitatea tutunurilor și trabucurilor noastre este confirmat de certificat de conformitate și calitate . Știm sa păstrăm trabucurile și tutunul - pentru aceasta avem  camere speciale asamblate cu umidificatori.',
                            speed: 'Viteza',
                            speedText: 'Plasarea unei comenzi pe site-ul nostru web va fi rapidă, ușoară și plăcută. Managerii vă vor contacta în 10 minute.',
                            exclusivity: 'Exclusivitate',
                            exclusivityText: 'Cauți de mult un anumit tip de trabuc sau tutun? Vom fi bucuroși să vă oferim o gamă largă de produse din tutun.'
                        },
                        QA: {
                            header: 'Cumparare si plata',
                            q1: 'CUM SE COMANDĂ?',
                            q1Text: 'Puteți plasa o comandă pe site, telefonic sau direct în magazin.',
                            q2: 'EXISTA O COMANDA MINIMA?',
                            q2Text: 'La comanda pe site, suma minima a comenzii dumneavoastra ar trebui sa fie de 250 lei, din care livrarea dumneavoastra devine gratuita, pana la 250 lei livrarea va costa 30 lei.',
                            q3: 'MA VA CONTACTA UN MANAGER?',
                            q3Text: 'După ce plasați o comandă pe site, un manager vă va contacta în termen de 30 de minute. El poate verifica detaliile comenzii.',
                            q4: 'TREBUIE SĂ PLATIȚI COMANDA Imediat?',
                            q4Text: 'Nu, plătiți comanda la primire. Acceptăm atât numerar, cât și carduri.',
                            conditions: 'CONDIȚII DE VÂNZARE',
                            conditionsText: 'torsedor.md nu vinde și nu livrează minori produse din tutun; Vă rugăm să citiți regulile acestui site.'
                        },
                        contacts: {
                            header: 'Contactează-ne!',
                            phonesHeader: 'Telefoane',
                            locationHeader: 'Magazinele'
                        }
                    },
                    loginForms: {
                        error: {
                            emptyFields: 'Vă rugăm să completați toate câmpurile!',
                            emailValidation: 'Vă rugăm să introduceți o adresă de email validă!',
                            emailIsUsed: 'Adresa de e-mail pe care ați introdus-o este deja utilizată!',
                            passwordLength: 'Parola trebuie să conțină cel puțin 8 caractere',
                            passwordMismatch: 'Parolele introduse nu se potrivesc!',
                            login: 'Ați introdus un e-mail sau o parolă incorectă!',
                            link: 'Linkul nu mai este valabil',
                            phoneFormat: 'Format de telefon greșit +373 xxx xx xxx!'
                        },
                        loginHeader: 'Intra',
                        backButton: 'înapoi',
                        name: 'Nume',
                        surname: 'Prenume',
                        phoneNumber: 'Telefon (+373)',
                        newsletterSubscribe: 'Adăugarea la Noutăți',
                        password: 'Parola',
                        newPassword: 'parolă nouă',
                        repeatPassword: 'repeta parola',
                        forgotPasswordLink: 'Ti-ai uitat parola?',
                        registrationLink: 'Creați un cont',
                        loginButton: 'Intra în cont',

                        restoreHeader: 'Restați parola',
                        restoreText: 'Introduceți adresa de e-mail a contului tău. Faceți clic pe butonul Continuați pentru a primi parola prin e-mail.',
                        restoreButton: 'Restați parola',
                        restoreInfo: 'Veți primi un e-mail cu o notificare de resetare a parolei.',
                        changePasswordHeader: 'Veți primi un e-mail cu o notificare de resetare a parolei.',
                        changePasswordButton: 'Schimbaţi parola',
                        registrationHeader: 'Înregistrare',
                        registrationButton: 'Crea un cont',
                        registrationAgreement1: 'Făcând clic pe „Creați un cont”, sunt de acord cu',
                        registrationAgreement2: 'termeni si conditii',
                        registrationAgreement3: 'utilizarea site-ului.'
                    },
                    profilePage: {
                        breadcrumbs: {
                            profile: 'Contul meu'
                        },
                        greeting: 'Salut, ',
                        totalOrdersSum: 'Suma totală de comenzi:',
                        orderHistoryHeader: 'Istoria cumparaturilor',
                        logoutButton: 'Ieși',

                        discountHeader: 'Sistem de reduceri',
                        discount: 'reducere', // Reducere 7%
                        discountInfo1: 'Sistemul reducerilor cumulate, puteti afla mai multe informatii contactand-ne, sau prin primire',
                        discountInfo2: 'info aici',
                        maxDiscount: 'Reducere maximă',

                        boughtItemsHeader: 'Bunuri achitate',
                        orderSum: 'Suma comenzilor: '
                    },
                    pageNotFound: {
                        header: 'Nu putem găsi această pagină',
                        subheader: 'Whoops, pagina nu a fost găsită!',
                        button: 'Acasă'
                    },
                    checkoutPage: { // Nou
                        breadcrumbs: {
                            checkout: 'Plasarea comenzii'
                        },
                        agreeCheckbox1: 'Am citit si sunt de acord cu',
                        agreeCheckbox2: 'Termenii și condiții',
                        expressDelivery: 'Livrarea express',
                        deliverySidebarHeader: 'Livrarea',
                        forms: {
                            city: 'Orașul',
                            address: 'adresa',
                            FLName: 'Numele complet',
                            phoneNumber: 'Număr de telefon +373',
                            comment: 'cometariu',
                            cashRadio: 'Plată în numerar',
                            cardRadio: 'Plata cu cardul la sosirea curierului',
                            noCallCheckbox: 'Nu suna inapoi la comanda'
                        },
                        error: 'Articolele din cosul dumneavoastra nu mai sunt disponibile!'
                    },
                    successPage: {
                        header: 'Multumesc pentru cumparaturile facute!',
                        message1: 'comanda este în proces',
                        message2: 'Am trimis o factură pe adresa dumneavoastră!',
                        mainPageButton: 'Acasă',
                        profileButton: 'Cabinet personal'
                    },
                    modals: {
                        oneClick: {
                            header: 'Cumpărați un produs cu singur clic. Completați formularul și managerul vă suna înapoi!',
                            FLNamePlaceholder: 'Numele complet',
                            phoneNumberPlaceholder: 'Telefon (+373)',
                            buyButton: 'Cumpără'
                        },
                        ageWarning: {
                            warningMessage: 'Vizitarea torsedor.md este permisă numai de la vârsta de 18 ani',
                            acceptButton: 'Da, am 18 ani',
                            declineButton: 'Nu, am sub 18 ani',
                            warningInfo: 'Făcând clic pe Da, confirmați că aveți vârsta legală pentru a cumpăra și utiliza produse din tutun.'
                        },
                        cookies: {
                            text: 'Folosim cookie-uri pentru a vă oferi o experiență mai bună pe site.',
                            infoButton: 'mai mult',
                            acceptButton: 'Accept'
                        },
                        timer: {
                            message: {
                                success: 'Codul dvs. promoțional a fost trimis pe adresa dvs. de e-mail',
                                emailIsUsed: 'E-mailul specificat a primit deja o reducere'
                            },
                            headerPrimary: 'Vă oferim o reducere',
                            headerSecondary: '10% cupon pentru orice produs!',
                            timerHeader: 'Cupon personal valabil',
                            formHeader: 'Lăsați-vă e-mailul și obțineți un cupon chiar acum!',
                            emailLabel: 'Email-ul tau',
                            actionButton: 'Obțineți cupon',
                            floatButton: 'Vrei o reducere?'
                        }
                    },
                    productCard: {
                        buyButton: 'Cumpără'
                    },
                    topBrands: {
                        header: 'Mărci de top'
                    },
                    CEOBlock: {
                        header: 'Produse Premium',
                        subheader: 'Cele mai bune produse ale brandurilor mondiale în Moldova!',
                        signature: 'Echipa Torsedor.md'
                    },
                    InstagramBlock: {
                        header: 'Urmărește-ne pe Instagram!',
                        subheader: 'Postări, oferte, tombole, produse noi și consultări în direct!',
                        subscribeButton: 'Subscribe'
                    },
                    footer: {
                        payDeliveryLink: 'Plata si livrarea',
                        aboutLink: 'Despre companie',
                        contactsLink: 'Contacte',
                        shopLink: 'Produse',
                        partnersInfoLink: 'Pentru parteneri',
                        restrictionText: 'Această resursă este destinată numai adulților. Ministerul Sănătății Republicii Moldova avertizează că fumatul dăunează sănătății tale.'
                    },
                    carousels: {
                        newCarouselHeader: 'Noul',
                        popularCarouselHeader: 'Popular',
                        discountCarouselHeader: '%Promoții',
                    }
                }
            }
        }
    });

export default i18n;