import './Row.css';

const Row = (props) => {
    let classes = 'row';
    if (props.className) {
        classes += ' ' + props.className;
    }

    const style = props.style;

    return (
      <div className={classes} style={style}>
          {props.children}
      </div>
    );
};

export default Row;