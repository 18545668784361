const priceProcessing = (price, demo = false) => {
    const priceWithoutDecimal = Math.floor(price);
    const decimal = Math.trunc((price - priceWithoutDecimal) * 100);
    price = priceWithoutDecimal;
    let result = '' + price;
    let numberOfPoints = Math.floor(result.length / 3);
    let currentPoint = result.length % 3;
    if (currentPoint === 0) {
        numberOfPoints--;
        currentPoint += 3;
    }

    while (numberOfPoints--) {
        result = result.slice(0, currentPoint) + '.' + result.slice(currentPoint);
        currentPoint += 4;
    }

    if (demo) {
        if (decimal > 0 && decimal < 10) result += '.' + decimal + '0';
        else if (decimal > 10) result += '.' + decimal;
        result += ' MDL';
    } else {
        result = 'MDL ' + result;
        if (+decimal === 0) result += '.00';
        else if (decimal < 10) result += '.' + decimal + '0';
        else result += '.' + decimal;
    }

    return result;
};

const formatPhone = (phone) => {
    phone = phone.trim();
    phone = phone.replace(' ', '');

    if (phone[0] === '0') {
        phone = phone.substring(1);
    }

    if (!phone.startsWith('+373')) {
        phone = '+373' + phone;
    }
    return phone;
};

export { priceProcessing, formatPhone };