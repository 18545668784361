const initialProductReducer = {
    productLoaded: false,
    product: {
        id: null,
        name_ru: '',
        name_ro: '',
        price: null,
        discount: null,
        description_ru: '',
        description_ro: '',
        feature_ru: '',
        feature_ro: '',
        main_image: null,
        subcategory_id: null,
        inStock: false,
        ProductAvailable: false
    },
    productImagesLoaded: false,
    productImages: []
};

const productReducer = (state, action) => {
    switch (action.type) {
        case 'SetLoadingProductInfo':
            return {
                ...state,
                productLoaded: false
            };
        case 'LoadProductInfo':
            return {
                ...state,
                product: {...action.payload},
                productLoaded: true
            };
        case 'SetLoadingProductImages':
            return {
                ...state,
                productImagesLoaded: false
            };
        case 'LoadProductImages':
            return {
                ...state,
                productImages: [...action.payload],
                productImagesLoaded: true
            };
        default:
            console.log('Something went wrong!');
            break;
    }
};

export {productReducer, initialProductReducer};