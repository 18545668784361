import {memo} from "react";
import {useTranslation} from "react-i18next";

import Row from "../../grid/Row";
import Container from "../../container/Container";
import TopBrand from "./TopBrand";
import SectionCenterHeader from "../../UI/Headers/SectionCenterHeader";
import Line from "../../UI/Line";

import './TopBrands.css';
import Davidoff from '../../../assets/img/top_brands_logos/davidoff_logo.png';
import Gizeh from '../../../assets/img/top_brands_logos/gizeh_logo.png';
import MacBaren from '../../../assets/img/top_brands_logos/macBaren_logo.png';
import Peterson from '../../../assets/img/top_brands_logos/peterson_logo.png';
import Zippo from '../../../assets/img/top_brands_logos/zippo_logo.png';
import AlladinLogo from "../../../assets/img/top_brands_logos/aladin.png";
import CasaturrentLogo from "../../../assets/img/top_brands_logos/Casaturrent_logo.png";
import ClubmasterLogo from "../../../assets/img/top_brands_logos/Clubmaster.png";
import StanleyLogo from "../../../assets/img/top_brands_logos/Stanley_logo.png";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './ProductCarousel.css';

 

const TopBrands = () => {
    const { t } = useTranslation();

    /* return (
        <section className='top-brands'>
            <Container>
                <SectionCenterHeader>{t('topBrands.header')}</SectionCenterHeader>
                <div className='top-brands__table'>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <TopBrand logo={Davidoff} url='Davidoff' />
                        <TopBrand logo={Gizeh} url='Gizeh' />
                        <TopBrand logo={MacBaren} url='Mac baren' />
                        <TopBrand logo={Peterson} url='Peterson' />
                        <TopBrand logo={Zippo} url='Zippo' />
                        <TopBrand logo={AlladinLogo} url='Aladin' />
                        <TopBrand logo={CasaturrentLogo} url='Casa_Turrent' />
                        <TopBrand logo={ClubmasterLogo} url='Clubmaster' />
                        <TopBrand logo={StanleyLogo} url='Stanley' />
                    </Row>
                </div>
                <Line />
            </Container>
        </section>
    );
    */
    const angle = '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" width="36" height="36" xml:space="preserve">\n' +
    '            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>\n' +
    '            <g><path d="M968.5,343.6c28.7-28.7,28.7-75.3,0-104c-28.7-28.7-75.3-28.7-104,0L544.9,559.1c-12.9,10-28.7,15.6-45.2,15.6c-16.5,0-32.4-5.6-45.2-15.6L135.6,240.2c-28.7-28.7-75.3-28.7-104,0c-28.7,28.7-28.7,75.3,0,104l416.1,416.1c13.8,13.8,32.5,21.5,52,21.5c19.5,0,38.2-7.7,52-21.5L968.5,343.6z"/></g>\n' +
    '        </svg>';
    const carouselOptions = {         
        loop: true,
        autoplay:true,
        autoplayTimeout:2500,
        autoplayHoverPause:true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots:false,
        responsive: {
            0: {
                items: 1.15,
                margin: 20,
                dots: false,
            },
            768: {
                items: 4,
                margin: 10,
                dots: true
            }
        },
    };  

    return (
        <section className='top-brands'>
        <Container>
            <SectionCenterHeader>{t('topBrands.header')}</SectionCenterHeader>             
                <div className='brands-carousel'>                   
                <OwlCarousel className='owl-theme' {...carouselOptions}>                   
                    <div className='item'>
                        <TopBrand logo={Gizeh} url='Gizeh' />
                    </div>
                    <div className='item'>
                        <TopBrand logo={MacBaren} url='Mac baren' />
                    </div>
                    <div className='item'>
                        <TopBrand logo={Peterson} url='Peterson' />
                    </div>
                    <div className='item'>
                        <TopBrand logo={Zippo} url='Zippo' />  
                    </div>
                    <div className='item'>
                        <TopBrand logo={AlladinLogo} url='Aladin' />
                    </div>
                    <div className='item'>
                        <TopBrand logo={CasaturrentLogo} url='Casa_Turrent' />
                    </div>
                    <div className='item'>
                        <TopBrand logo={ClubmasterLogo} url='Clubmaster' />
                    </div>
                    <div className='item'>
                        <TopBrand logo={StanleyLogo} url='Stanley' />
                    </div>
                </OwlCarousel>
                </div>
                <Line />
        </Container>
        </section>
    );

};

//export default TopBrands;
export default memo(TopBrands);