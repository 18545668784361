import './Backdrop.css';

const Backdrop = (props) => {
    let classes = 'backdrop';
    if (props.className) classes += ' ' + props.className;

    return (
        <div className={classes} onClick={props.onClick}>
            {props.children}
        </div>
    );
};

export default Backdrop;