import {Fragment, useEffect, useReducer, useState} from "react";
import {CEOReducer, initialCEOReducer} from "../../../reducers/CEOReducer";
import {getCEOText} from "../../../api/CEO";
import {useTranslation} from "react-i18next";

import SlideDown from "react-slidedown/lib/slidedown";
import Container from "../../container/Container";
import Row from "../../grid/Row";
import Col from "../../grid/Col";
import Line from "../../UI/Line";
import Ball from "../../Ball";

import './CEO.css';
import 'react-slidedown/lib/slidedown.css'
import BigAngleIcon from "../../../assets/icons/BigAngleIcon";
import CeoHeaderImage from '../../../assets/img/tmp/ceo-block-image.png';

const CEO = (props) => {
    const { t, i18n } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const [CEOState, CEODispatch] = useReducer(CEOReducer, initialCEOReducer);

    useEffect(() => {
        getCEOText(CEODispatch, props.subcategory);
    }, []);

    const CEOText = CEOState['CEOText' + i18n.resolvedLanguage.toUpperCase()].split('\n');

    const hiddenContent = (
        <Fragment>
            <Row className='ceo-block__divider justify-content-center'>
                <Col col={10}>
                    <Line dark />
                </Col>
            </Row>
            <Row>
                <Col col={12} className='ceo-block__sliding-text'>
                    {
                        CEOState.CEOLoaded && CEOText.map((item, index) => {
                            if (index < 2) return;
                            return <p key={index}>{item}</p>
                        })
                    }
                </Col>
                <Col col={12} className='d-flex justify-content-end'>
                    <span className='ceo-block__author'>{t('CEOBlock.signature')}</span>
                </Col>
            </Row>
        </Fragment>
    );

    const showContentHandler = () => {
      setIsOpened(prevState => !prevState);
    };

    return (
        <section className='ceo-block p-relative'>
            <Container>
                <div className='ceo-block__body'>
                    <Row>
                        <Col col={8} className='ceo-block__always-shown-text'>
                            <div className='desktop-only'>
                                <h1>{t('CEOBlock.header')}</h1>
                                <h2>{t('CEOBlock.subheader')}</h2>
                            </div>
                            {CEOState.CEOLoaded && (
                                <div>
                                    <p>{CEOText[0]}</p>
                                    <p>{CEOText[1]}</p>
                                </div>
                            )}
                        </Col>
                        <Col col={4} className='ceo-block__header-image'>
                            <div className='tablet-only'>
                                <h1>{t('CEOBlock.header')}</h1>
                                <h2>{t('CEOBlock.subheader')}</h2>
                            </div>
                            <div className='ceo-block__image-mobile'>
                                <img src={CeoHeaderImage} alt='' className='w-100' />
                            </div>
                        </Col>
                    </Row>
                    <SlideDown className={'ceo-slide-down'}>
                        { isOpened ? hiddenContent : null }
                    </SlideDown>
                    <Row>
                        <Col col={12} className='d-flex ceo-block__controls'>
                            <BigAngleIcon up={isOpened} onClick={showContentHandler} />
                        </Col>
                    </Row>
                </div>
                <Ball
                    background='#FFB800'
                    top='0'
                    left='-150px'
                />
            </Container>
        </section>
    );
}

export default CEO;