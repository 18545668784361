import {addToCart, deleteFromCart} from "../../api/Cart";
import {getProductInfoAsync} from "../../api/ProductInfo";

const deleteCartItemHandler = (id, cartCtx, loginCtx) => {
    cartCtx.deleteItem(id);
    if (loginCtx.isLoggedIn()) {
        const session_id = loginCtx.getSessionId();
        deleteFromCart(id, session_id);
    } else {
        deleteFromCart(id);
    }
}

const increaseCartItemHandler = async (id, cartCtx, loginCtx) => {
    cartCtx.increaseItem(id);
    if (loginCtx.isLoggedIn()) {
        const session_id = loginCtx.getSessionId();
        await addToCart(id, 1, session_id);
    } else {
        await addToCart(id, 1);
    }
}

const decreaseCartItemHandler = async (id, cartCtx, loginCtx) => {
    cartCtx.decreaseItem(id);
    if (loginCtx.isLoggedIn()) {
        const session_id = loginCtx.getSessionId();
        await addToCart(id, -1, session_id);
    } else {
        await addToCart(id, -1);
    }
}

const addToCartHandler = async (id, cartCtx, loginCtx, amount = 1) => {
    const item = await getProductInfoAsync(id);
    const cartItem = {
        id: item.id,
        name_ru: item.name_ru,
        name_ro: item.name_ro,
        subcategory_id: item.subcategory_id,
        price: item.price,
        discount: item.discount,
        amount: amount,
        image: item.main_image
    };
    cartCtx.addItem(cartItem);

    if (loginCtx.isLoggedIn()) {
        const session_id = loginCtx.getSessionId();
        await addToCart(item.id, amount, session_id);
    } else {
        await addToCart(item.id, amount);
    }
};

export {decreaseCartItemHandler, increaseCartItemHandler, deleteCartItemHandler, addToCartHandler}