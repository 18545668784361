import {useTranslation} from "react-i18next";

import GiftIcon from "../../../assets/icons/GiftIcon";

import './FloatButton.css';

const FloatButton = (props) => {
    const { t } = useTranslation();

    return (
        <button className='float-button white-text d-flex align-items-center' onClick={props.onClick}>
            <GiftIcon /> {t('modals.timer.floatButton')}
        </button>
    );
};

export default FloatButton;