import {useEffect, useState} from "react";
import {getDiscounts} from "../../api/GlobalDiscount";
import {useTranslation} from "react-i18next";

import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import InfoSlider from "./InfoSlider";
import InfoFeature from "./InfoFeature";
import SidebarHeader from "../../components/UI/Headers/SidebarHeader";
import SidebarContainer from "../../components/container/SidebarContainer";
import Line from "../../components/UI/Line";
import Location from "../Contacts/Location";
import Instagram from "../../components/blocks/Instagram/Instagram";
import Ball from "../../components/Ball";

import './InfoPage.css';

const InfoPage = () => {
    const { t } = useTranslation();
    const [discountInfo, setDiscountInfo] = useState([]);

    useEffect(() => {
        const fetchDiscountData = async () => {
            setDiscountInfo(await getDiscounts());
        };
        fetchDiscountData();
    }, []);

    let discounts = null;
    if (discountInfo.length !== 0) {
        discounts = discountInfo.map(discount => {
            if (discount.max === null) {
                return (
                    <li key={discount.id}>
                        > {discount.min} {t('infoPage.modules.module2.currency')} = {discount.discount_amount}%
                    </li>
                );
            }
            return (
                <li key={discount.id}>
                    {discount.min} {t('infoPage.modules.module2.currency')} – {discount.max} {t('infoPage.modules.module2.currency')} = {discount.discount_amount}%
                </li>
            );
        });
    }

    const moduleToList = (text) => {
        return text.split('\n').map((item, index) => {
            return (
                <li key={index}>{item}</li>
            )
        });
    }

    return (
        <main>
            <section className='info-page p-relative'>
                <Container>
                    <Row>
                        <Col col={12}>
                            <h1 className='info-page__title primary-text'>{t('infoPage.header')}</h1>
                            <InfoSlider title={t('infoPage.modules.module1.title')}>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module1.paragraph1')}
                                </p>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module1.paragraph2')}
                                </p>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module1.paragraph3')}
                                </p>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module1.paragraph4')}
                                </p>
                            </InfoSlider>
                            <InfoSlider title={t('infoPage.modules.module2.title')}>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module2.addressing')}
                                </p>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module2.paragraph1')}
                                </p>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module2.sumText')}
                                </p>
                                <ul type='none' className='info-page__info'>
                                    {discounts}
                                </ul>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module2.paragraph2')}
                                </p>
                            </InfoSlider>
                            <InfoSlider title={t('infoPage.modules.module3.title')}>
                                <h3 className='primary-text'>1. {t('infoPage.modules.module3.header1')}</h3>
                                <p className='info-page__info'>{t('infoPage.modules.module3.text1')}</p>
                                <h3 className='primary-text'>2. {t('infoPage.modules.module3.header2_1')}</h3>
                                <h3 className='primary-text'>3. {t('infoPage.modules.module3.header2')}</h3>
                                <p className='info-page__info'>{t('infoPage.modules.module3.text2')}</p>
                                <h3 className='primary-text'>4. {t('infoPage.modules.module3.header3')}</h3>
                                <p className='info-page__info'>{t('infoPage.modules.module3.text3')}</p>
                                <h3 className='primary-text'>5. {t('infoPage.modules.module3.header4')}</h3>
                                <p className='info-page__info'>{t('infoPage.modules.module3.text4')}</p>
                                <h3 className='primary-text'>6. {t('infoPage.modules.module3.header6')}</h3>
                                <p className='info-page__info'>{t('infoPage.modules.module3.text6')}</p>

                                <h3 className='primary-text'>18+ {t('infoPage.modules.module3.header5')}</h3>
                                <p className='info-page__info'>{t('infoPage.modules.module3.text5')}</p>                                
                            </InfoSlider>
                            <InfoSlider title={t('infoPage.modules.module4.title')}>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module4.text1')}
                                </p>
                                <h3 className='primary-text'>{t('infoPage.modules.module4.header1')}</h3>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module4.text2')}
                                </p>
                                <ul className='info-page__info'>
                                    {moduleToList(t('infoPage.modules.module4.list1'))}
                                </ul>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module4.text3')}
                                </p>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module4.text4')}
                                </p>
                                <ol className='info-page__info'>
                                    {moduleToList(t('infoPage.modules.module4.list2'))}
                                </ol>
                                <h3 className='primary-text'>{t('infoPage.modules.module4.header2')}</h3>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module4.text5')}
                                </p>
                                <ol className='info-page__info'>
                                    {moduleToList(t('infoPage.modules.module4.list3'))}
                                </ol>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module4.text6')}
                                </p>
                                <p className='info-page__info'>
                                    {t('infoPage.modules.module4.text7')}
                                </p>
                            </InfoSlider>
                            {/*<InfoSlider title={t('infoPage.modules.module5')}>*/}
                            {/*    <p className='info-page__info'>*/}
                            {/*        Tabacco House – магазин табачных изделий и место, благодаря которому курение превращается в настоящее искусство. У нас вы найдёте широчайший спектр товаров для курения от топовых производителей, таких как Mac Baren, Arnold Andre, Davidoff, TeAmo, Gizeh, DUD, Zippo, Peterson, Planta, Altadius U.S.A. и многих других.*/}
                            {/*    </p>*/}
                            {/*    <p className='info-page__info'>*/}
                            {/*        Наш ассортимент включает в себя: сигаретный табак Mac Baren, трубочный табак Peterson, машинки, бумагу, гильзы, фильтры для самокруток от фирмы Gizeh, оригинальные зажигалки и другие аксессуары Zippo, сигары и сигариллы Arnold Andre, Davidoff, Te Amo, кальяны от голландской фирмы DUD, а также табак и уголь для кальянов AL Fakher и Serbetli. Перечень товаров постоянно пополняется новинками, среди которых каждый сможет найти что-то интересное.*/}
                            {/*    </p>*/}
                            {/*    <p className='info-page__info'>*/}
                            {/*        Кроме того, в Tabacco House вы можете приобрести сертификат на сумму 500, 1000 и 2000 леев, который станет отличным подарком для настоящего ценителя качественных табачных изделий.*/}
                            {/*    </p>*/}
                            {/*    <p className='info-page__info'>*/}
                            {/*        Наш огромный и разнообразный ассортимент представлен в 4 магазинах по всему Кишинёву. Там вас встретят квалифицированные продавцы, которые ответят на все интересующие вопросы и помогут сделать правильный выбор. Уверены, Вам захочется вернуться к нам ещё не один раз.*/}
                            {/*    </p>*/}
                            {/*</InfoSlider>*/}

                            {/*<div className='info-page__distance' />*/}

                            {/*<p className='info-page__info'>*/}
                            {/*    Tabacco House – магазин табачных изделий и место, благодаря которому курение превращается в настоящее искусство. У нас вы найдёте широчайший спектр товаров для курения от топовых производителей, таких как Mac Baren, Arnold Andre, Davidoff, TeAmo, Gizeh, DUD, Zippo, Peterson, Planta, Altadius U.S.A. и многих других.*/}
                            {/*</p>*/}
                            {/*<p className='info-page__info'>*/}
                            {/*    Наш ассортимент включает в себя: сигаретный табак Mac Baren, трубочный табак Peterson, машинки, бумагу, гильзы, фильтры для самокруток от фирмы Gizeh, оригинальные зажигалки и другие аксессуары Zippo, сигары и сигариллы Arnold Andre, Davidoff, Te Amo, кальяны от голландской фирмы DUD, а также табак и уголь для кальянов AL Fakher и Serbetli. Перечень товаров постоянно пополняется новинками, среди которых каждый сможет найти что-то интересное.*/}
                            {/*</p>*/}
                            {/*<p className='info-page__info'>*/}
                            {/*    Кроме того, в Tabacco House вы можете приобрести сертификат на сумму 500, 1000 и 2000 леев, который станет отличным подарком для настоящего ценителя качественных табачных изделий.*/}
                            {/*</p>*/}
                            {/*<p className='info-page__info'>*/}
                            {/*    Наш огромный и разнообразный ассортимент представлен в 4 магазинах по всему Кишинёву. Там вас встретят квалифицированные продавцы, которые ответят на все интересующие вопросы и помогут сделать правильный выбор. Уверены, Вам захочется вернуться к нам ещё не один раз.*/}
                            {/*</p>*/}
                        </Col>
                    </Row>
                    <Ball
                        background='#E4B948'
                        top={0}
                        right={0}
                    />
                    <Ball
                        background='#FE7A00'
                        top='600px'
                        left={0}
                    />
                </Container>
            </section>
            <section className='info-page'>
                <Container>
                    <Row>
                        <Col col={12}>
                            <h1 className='info-page__title primary-text'>{t('infoPage.advantages.header')}</h1>
                            <InfoFeature title={t('infoPage.advantages.quality')}>
                                {t('infoPage.advantages.qualityText')}
                            </InfoFeature>
                            <InfoFeature title={t('infoPage.advantages.speed')}>
                                {t('infoPage.advantages.speedText')}
                            </InfoFeature>
                            <InfoFeature title={t('infoPage.advantages.exclusivity')}>
                                {t('infoPage.advantages.exclusivityText')}
                            </InfoFeature>
                        </Col>
                    </Row>
                </Container>
            </section>
             
            <section className='info-page p-relative'>
                <Container>
                    <Row>
                        <Col col={12}>
                            <h1 className='info-page__title primary-text'>{t('infoPage.contacts.header')}</h1>
                            <p className='grey-text info-page__working-hours'>
                                {t('contactPage.workHoursText')} <br />
                                {t('contactPage.workHoursInfo')} <br />
                                {t('contactPage.workHoursInfo2')}
                            </p>
                            <div className='info-page__distance' />
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                            <aside className='info-page__aside'>
                                <SidebarHeader className='info-page__aside-header' text={t('infoPage.contacts.phonesHeader')} />
                                <SidebarContainer>
                                    <p><a href='tel:+37379556644' className='text-underline primary-text info-page__contact'>+373 795 56 644</a></p>
                                </SidebarContainer>
                                {/*<Line dark />*/}
                                {/*<SidebarHeader className='info-page__aside-header' text={t('infoPage.contacts.locationHeader')} />*/}
                                {/*<SidebarContainer>*/}
                                {/*    <Location className='info-page__contact' primary location={t('contactPage.location1')} from='09:00' to='19:00' url='https://www.google.com/maps/place/Uptown+Caf%C3%A9/@47.015385,28.832837,15z/data=!4m5!3m4!1s0x0:0x6c6f1f760f2511ee!8m2!3d47.0180012!4d28.8260638' />*/}
                                {/*    <Location className='info-page__contact' primary location={t('contactPage.location2')} from='09:30' to='19:30' url='https://www.google.com/maps/place/Uptown+Caf%C3%A9/@47.015385,28.832837,15z/data=!4m5!3m4!1s0x0:0x6c6f1f760f2511ee!8m2!3d47.0180012!4d28.8260638' />*/}
                                {/*    <Location className='info-page__contact' primary location={t('contactPage.location3')} from='10:00' to='20:00' url='https://www.google.com/maps/place/Uptown+Caf%C3%A9/@47.015385,28.832837,15z/data=!4m5!3m4!1s0x0:0x6c6f1f760f2511ee!8m2!3d47.0180012!4d28.8260638' />*/}
                                {/*</SidebarContainer>*/}
                            </aside>
                        </Col>
                    </Row>
                    <Ball
                        background='#E49348'
                        top='300px'
                        left={0}
                    />
                    <Ball
                        background='#FE7A00'
                        top='0'
                        right={0}
                    />
                </Container>
            </section>
            <Instagram />
        </main>
    );
};

export default InfoPage;