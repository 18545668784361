import React from 'react';

const CartContext = React.createContext({
    items: [],
    totalAmount: 0,
    isOpened: false,
    addItem: (item) => { },
    increaseItem: (id) => { },
    decreaseItem: (id) => { },
    deleteItem: (id) => { },
    clearCart: () => { },
    openCart: () => { },
    closeCart: () => { },
    toggleCart: () => { }
});

export default CartContext;