import './AngleIcon.css';

const BigAngleIcon = (props) => {
    let position = '';
    if (props.up) {
        position = 'up';
    }

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="19" viewBox="0 0 36 19" fill="none" className={position} onClick={props.onClick}>
          <path d="M0.790756 4.23071C-0.263584 3.2634 -0.263584 1.69279 0.790756 0.725479C1.8451 -0.24183 3.55702 -0.24183 4.61136 0.725479L16.3524 11.494C16.8263 11.831 17.4067 12.0197 18.0129 12.0197C18.619 12.0197 19.2031 11.831 19.6734 11.494L31.3886 0.745704C32.443 -0.221605 34.1549 -0.221605 35.2092 0.745704C36.2636 1.71301 36.2636 3.28363 35.2092 4.25094L19.9232 18.2752C19.4162 18.7404 18.7292 18.9999 18.0129 18.9999C17.2965 18.9999 16.6095 18.7404 16.1026 18.2752L0.790756 4.23071Z" fill="#CA9D2A"/>
      </svg>
    );
};

export default BigAngleIcon;