import {useState} from "react";

import SlideDown from "react-slidedown/lib/slidedown";
import SmallAngleIcon from "../../assets/icons/SmallAngleIcon";

import './InfoSlider.css';

const InfoSlider = (props) => {
    const [isOpened, setIsOpened] = useState(false);

    const openInfoHandler = () => setIsOpened(prevState => !prevState);

    return (
        <article>
            <div className='info-slider__header d-flex align-items-center' onClick={openInfoHandler}>
                <SmallAngleIcon down={isOpened} />
                <h3 className='info-slider__title white-text'>{props.title}</h3>
            </div>
            <SlideDown className={'ceo-slide-down'}>
                { isOpened ? props.children : null }
            </SlideDown>
        </article>
    );
};

export default InfoSlider;