const initialCategoryReducer = {
    categories: [],
    categoryLoaded: false
};

const categoryReducer = (state, action) => {
    switch (action.type) {
        case 'SetLoadingCategory':
            return {
                ...state,
                categoryLoaded: false
            }
        case 'LoadCategories':
            return {
                ...state,
                categories: [...Object.entries(action.payload)],
                categoryLoaded: true
            }

        default:
            console.log('Something went wrong!');
            break;
    }
};

export {categoryReducer, initialCategoryReducer};