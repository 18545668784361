import {apiAddress} from "./APILink";

const getPromoCarousel = (dispatch) => {
    dispatch({type: 'SetLoadingPromoCarousel'});
    fetch(apiAddress + 'api/scripts/PromoCarousel.php', {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadPromoCarousel', payload: data});
        });
};

const getNewProductsCarousel = (dispatch, id = null) => {
    dispatch({type: 'SetLoadingNewCarousel'})
    fetch(apiAddress + 'api/scripts/NewProductCarousel.php', {method: 'get'})
        .then(response => response.json())
        .then(data => {
            if (id != null)
                data = data.filter(item => item.product_id !== id);
            dispatch({type: 'LoadNewCarousel', payload: data});
        });
};

const getPopularProductsCarousel = (dispatch) => {
    dispatch({type: 'SetLoadingPopularCarousel'});
    fetch(apiAddress + 'api/scripts/PopularProductCarousel.php', {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadPopularCarousel', payload: data});
        });
};

const getDiscountProductsCarousel = (dispatch) => {
    dispatch({type: 'SetLoadingDiscountCarousel'});
    fetch(apiAddress + 'api/scripts/DiscountProductCarousel.php', {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadDiscountCarousel', payload: data});
        });
};

export {getPromoCarousel, getNewProductsCarousel, getPopularProductsCarousel, getDiscountProductsCarousel};