import {memo} from "react";
import {useTranslation} from "react-i18next";

import OwlCarousel from 'react-owl-carousel';
import Col from "../grid/Col";
import HollowButton from "../UI/Buttons/HollowButton";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './PromoCarousel.css';

const PromoCarousel = (props) => {
    const {i18n} = useTranslation();

    const carouselOptions = {
        autoplay:true,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        loop: true,
        responsive: {
            0: {
                items: 1.15,
                margin: 20,
                dots: false,
            },
            768: {
                items: 1,
                margin: 10,
                dots: true
            }
        },
    };

    const linkClickHandler = (link) => {
        window.location.href = link;
    };

    const carouselItems = props.carouselInfo.map((item) => {
        return (
            <article className='d-flex align-items-center item' key={item.id}>
                <Col col={6} className='promo-carousel__info' style={{backgroundImage: `url(${item.image_mobile})`}}>
                    <h1 className='promo-carousel__main-text primary-text'>{item['title_' + i18n.resolvedLanguage]}</h1>
                    <h4 className='promo-carousel__secondary-text light-text'>{item['subtitle_' + i18n.resolvedLanguage]}</h4>
                    <div style={{marginBottom: '20px'}}>
                        <HollowButton text={item['button_' + i18n.resolvedLanguage]} onClick={() => linkClickHandler(item.url)} />
                    </div>
                </Col>
                <Col col={6} className='promo-carousel__image'>
                    <img src={item.image} alt='' />
                </Col>
            </article>
        );
    });

    return (
        <OwlCarousel className='promo-carousel owl-theme' {...carouselOptions}>
            {carouselItems}
        </OwlCarousel>
    );
};

export default memo(PromoCarousel);