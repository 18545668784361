import {Link, useNavigate} from "react-router-dom";
import {priceProcessing} from "../../helpers/Processors";
import {useTranslation} from "react-i18next";

import AddToCart from "../UI/Buttons/AddToCart";
import SolidButton from "../UI/Buttons/SolidButton";

import './ProductCard.css';

const ProductCard = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const price = priceProcessing(props.price);

    let discount = <div className='discount__margin' />;
    let oldPriceClass = '';
    if (props.discount !== null) {
        discount = <span className='product-card__price fw-bold secondary-text'>{priceProcessing(props.discount)}</span>;
        oldPriceClass = 'old-price';
    }
    const src = props.image;
    const url = '/shop/' + props.subcategory_id + '/' + props.id;

    const goDetails = () => {
        navigate(url);
    };

    return (
      <article className='product-card'>
          <Link to={url} className='product-card__block-link'>
              <div className='product-card__image'>
                  <img src={src} className='w-100' alt='' />
              </div>
              <div className='product-card__padding'>
                  <h1 className='product-card__title light-text'>{props.title}</h1>
                  <h5 className='product-card__category dark-text'>{props.category}</h5>
                  <div className='discount__block'>
                      {discount}
                  </div>
                  <div>
                      <span className={`product-card__price fw-bold primary-text ${oldPriceClass}`}>{price}</span>
                  </div>
              </div>
          </Link>
          <div className='product-card__controls product-card__padding'>              
              <AddToCart id={props.id} />
          </div>
      </article>
    );
};

export default ProductCard;