import {useTranslation} from "react-i18next";

import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import Location from "./Location";
import InputContactForm from "../../components/UI/Form/InputContactForm";
import TextareaContactForm from "../../components/UI/Form/TextareaContactForm";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import Ball from "../../components/Ball";

import './ContactPage.css';
import TelegramLogo from "../../assets/icons/TelegramLogo";
import InstagramLogo from "../../assets/icons/InstagramLogo";
import FacebookSquareLogo from "../../assets/icons/FacebookSquareLogo";
import useInput from "../../hooks/use-input";
import {isEmail} from "../../helpers/Validator";
import {sendCallbackForm} from "../../api/Contacts";
import ErrorMessage from "../../components/UI/Error/ErrorMessage";
import {useState} from "react";
import ComponentLoading from "../../components/loading/ComponentLoading";

const ContactPage = () => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    const [isSending, setIsSending] = useState(false);

    const {
        value: FLName,
        isValid: enteredFLNameIsValid,
        reset: FLNameReset,
        valueChangeHandler: FLNameChangedHandler,
        inputBlurHandler: FLNameBlurHandler
    } = useInput(value => value.trim() !== '');

    const {
        value: email,
        isValid: enteredEmailIsValid,
        reset: emailReset,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput(value => isEmail(value));

    const {
        value: message,
        isValid: enteredMessageIsValid,
        reset: messageReset,
        valueChangeHandler: messageChangedHandler,
        inputBlurHandler: messageBlurHandler
    } = useInput(value => value.trim() !== '');

    const formIsValid = enteredEmailIsValid && enteredFLNameIsValid && enteredMessageIsValid;

    const sendForm = (e) => {
        e.preventDefault();
        if (formIsValid) {
            setIsSending(true);
            sendCallbackForm(FLName, email, message)
                .then(res => res.json())
                .then(data => {
                    setIsSending(false);
                    if (data.error === 'ok') {
                        messageReset();
                        emailReset();
                        FLNameReset();
                    } else {
                        setErrorMessage(t('contactPage.forms.somethingWrong'));
                    }
                })
        }
    };

    return (
        <main className='p-relative'>
            <section className='contact'>
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={5}>
                            <h1 className='contact__title primary-text'>{t('contactPage.header')}</h1>
                            <p className='grey-text contact__info'>
                                {t('contactPage.workHoursText')} <br />
                                {t('contactPage.workHoursInfo')} <br />
                                {t('contactPage.workHoursInfo2')}
                            </p>
                            {/*<Location location={t('contactPage.location1')} from='09:00' to='19:00' url='https://www.google.com/maps/place/Uptown+Caf%C3%A9/@47.015385,28.832837,15z/data=!4m5!3m4!1s0x0:0x6c6f1f760f2511ee!8m2!3d47.0180012!4d28.8260638' />*/}
                            {/*<Location location={t('contactPage.location2')} from='09:30' to='19:30' url='https://www.google.com/maps/place/Uptown+Caf%C3%A9/@47.015385,28.832837,15z/data=!4m5!3m4!1s0x0:0x6c6f1f760f2511ee!8m2!3d47.0180012!4d28.8260638' />*/}
                            {/*<Location location={t('contactPage.location3')} from='10:00' to='20:00' url='https://www.google.com/maps/place/Uptown+Caf%C3%A9/@47.015385,28.832837,15z/data=!4m5!3m4!1s0x0:0x6c6f1f760f2511ee!8m2!3d47.0180012!4d28.8260638' />*/}

                            <p><a href='tel:+37379556644' className='primary-text contact__tel'>+373 795 56 644</a></p>

                            <h2 className='contact__subtitle primary-text'>Social Media</h2>
                            <div className='d-flex align-items-center'>
                                <InstagramLogo />&nbsp;&nbsp;<TelegramLogo /> 
                            </div>
                        </Col>
                        {/*<Col sm={12} md={6} lg={7}>*/}
                        {/*    <iframe*/}
                        {/*        title='map-location' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10881.063694614162!2d28.832837!3d47.015385!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6c6f1f760f2511ee!2sUptown%20Caf%C3%A9!5e0!3m2!1sru!2sro!4v1648474715394!5m2!1sru!2sro"*/}
                        {/*        className='contact__map' allowFullScreen="" loading="lazy"*/}
                        {/*        referrerPolicy="no-referrer-when-downgrade" />*/}
                        {/*</Col>*/}
                        <Col sm={12} md={12} lg={7}>
                            <div className='contact-form__body'>
                                <form onSubmit={sendForm}>
                                    <Col sm={12} md={5}>
                                        <InputContactForm id='name'
                                                          value={FLName}
                                                          onChange={FLNameChangedHandler}
                                                          onBlur={FLNameBlurHandler}
                                                          label={t('contactPage.forms.name')}
                                                          required
                                        />
                                    </Col>
                                    <Col sm={12} md={5}>
                                        <InputContactForm id='email'
                                                          value={email}
                                                          onChange={emailChangedHandler}
                                                          onBlur={emailBlurHandler}
                                                          label='Email'
                                                          required
                                        />
                                    </Col>
                                    <Col sm={12} md={10}>
                                        <TextareaContactForm id='message'
                                                             value={message}
                                                             onChange={messageChangedHandler}
                                                             onBlur={messageBlurHandler}
                                                             label={t('contactPage.forms.message')}
                                                             required
                                        />
                                    </Col>
                                    <ErrorMessage errorMessage={errorMessage} />
                                    <div className='contact-form__control'>
                                        { isSending && <ComponentLoading justifyContent='flex-start' /> }
                                        { !isSending &&
                                            <SolidButton className='w-25' text={t('contactPage.forms.sendButton')} primary disabled={!formIsValid} /> }
                                    </div>

                                </form>
                            </div>
                        </Col>
                    </Row>
                    {/*<Row className='justify-content-end'>*/}
                    {/*    */}
                    {/*</Row>*/}
                    <Ball
                        background='#FE0098'
                        top='-100px'
                        left={0}
                    />
                    <Ball
                        background='#FE7A00'
                        top='300px'
                        left={0}
                    />
                    <Ball
                        background='#E4B948'
                        top={0}
                        right={0}
                    />
                </Container>
            </section>
        </main>
    );
};

export default ContactPage;