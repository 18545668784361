import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {makeOrder} from "../../api/Order";
import CartContext from "../../store/cart-context";
import LoginContext from "../../store/login-context";
import {emptyLocalCart} from "../../api/Cart";
import {useTranslation} from "react-i18next";
import {getDiscountValue} from "../../api/GlobalDiscount";
import {getCodeById} from "../../api/Code";

import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import CheckoutInfo from "./CheckoutInfo";
import CheckoutTotal from "./CheckoutTotal";
import Ball from "../../components/Ball";

import './Checkout.css';

const Checkout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);

    const [showLoad, setShowLoad] = useState(false);
    const [code, setCode] = useState(null);
    const [selectedCity, setSelectedCity] = useState(1);
    const [comment, setComment] = useState('');
    const [paymentMethod, setPaymentMethod] = useState(1);
    const [recall, setRecall] = useState(true);
    const [address, setAddress] = useState('');
    const [FLName, setFLName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneIsValid, setPhoneIsValid] = useState(loginCtx.isLoggedIn());
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [FLNameIsValid, setFLNameIsValid] = useState(loginCtx.isLoggedIn());
    const [addressIsValid, setAddressIsValid] = useState(false);
    const [isExpress, setIsExpress] = useState(false);
    const [agreement, setAgreement] = useState(false);

    const [discountValue, setDiscountValue] = useState(0);

    useEffect(() => {
        // if (cartCtx.totalAmount < 200) navigate('/cart');
        const fetchDiscount = async () => {
            const response = await getDiscountValue(loginCtx);
            if (sessionStorage.getItem('code')) {
                const tmp = await getCodeById(sessionStorage.getItem('code'), loginCtx);
                if (tmp.error === 'ok' && tmp.code !== null) {
                    setCode(tmp.code);
                }
                sessionStorage.removeItem('code');
            }
            if (response.error === 'ok') {
                setDiscountValue(response.value);
            }
        };
        fetchDiscount();
    }, []);

    const hierarchy = [
        {
            name: t('checkoutPage.breadcrumbs.checkout')
        }
    ];

    const makeOrderHandler = async () => {
        if (formIsValid) {
            setShowLoad(true);
            const response = await makeOrder(loginCtx, selectedCity, address, FLName, phone, comment, paymentMethod, isExpress, recall, code)
            if (response.error === 'ok') {
                cartCtx.clearCart();
                emptyLocalCart();

                const {error, ...gtagData} = response;
                window.gtag("event", "purchase", gtagData);

                navigate('/success');
            } else if (response.error === 3) {
                alert(t('checkoutPage.error'));
                emptyLocalCart();
                navigate('/cart');
            } else {
                alert('Something went wrong! Please, try again later!');
                navigate('/cart');
            }
            setShowLoad(false);
        }
    }

    const formIsValid = FLNameIsValid && phoneIsValid && addressIsValid && agreement && !showLoad;
    //let totalPriceDiscounted = +cartCtx.totalAmount * (1 - discountValue / 100);
    let totalPriceDiscounted = +cartCtx.totalAmount;
   //if (code !== null) totalPriceDiscounted *= (1 - code.discount / 100);

   // sk 
   const arr = cartCtx.items.map(({ discount }) =>  discount);
   const sumarr = arr.reduce((a, b) => Math.trunc(a) + Math.trunc(b), 0);
   totalPriceDiscounted = totalPriceDiscounted - sumarr;
    //scenariu 1
    if (discountValue !== null && code === null) {
        if(totalPriceDiscounted === sumarr) {
            //totalPriceDiscounted *= (1 - discountValue / 100);
        } else {
            totalPriceDiscounted *= (1 - discountValue / 100);
        }
         
    }
    //scenariu 2 (fara dreptul la promo code)
    if (code !== null && totalPriceDiscounted !== sumarr) {
       // sk
        totalPriceDiscounted *= (1 - code.discount / 100);
    }
   // Fin
   totalPriceDiscounted = totalPriceDiscounted + sumarr;


    return (
        <main className='p-relative'>
            <Container>
                <Row>
                    <Col col={12}>
                        <Breadcrumbs hierarchy={hierarchy} />
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col sm={12} md={6} xl={4}>
                        <CheckoutInfo
                            selectedCity={selectedCity}
                            onSelectCity={setSelectedCity}
                            comment={comment}
                            onComment={setComment}
                            paymentMethod={paymentMethod}
                            onPaymentMethod={setPaymentMethod}
                            recall={recall}
                            onRecall={setRecall}
                            onAddress={setAddress}
                            onAddressValid={setAddressIsValid}
                            onFLName={setFLName}
                            onFLNameValid={setFLNameIsValid}
                            onEmail={setEmail}
                            onEmailValid={setEmailIsValid}
                            onPhone={setPhone}
                            onPhoneValid={setPhoneIsValid}
                        />
                    </Col>
                    <Col sm={12} md={6} xl={4}>
                        <CheckoutTotal
                            agreement={agreement}
                            onAgreement={setAgreement}
                            isExpress={isExpress}
                            onExpress={setIsExpress}
                            valid={formIsValid}
                            totalAmount={cartCtx.totalAmount}
                            totalAmountDiscounted={totalPriceDiscounted}
                            showLoad={showLoad}
                            onSubmit={makeOrderHandler}
                        />
                    </Col>
                </Row>
                <Ball
                    background='#FE0098'
                    bottom='0'
                    right={0}
                />
                <Ball
                    background='#FE7A00'
                    bottom='0'
                    left={0}
                />
                <Ball
                    background='#E4B948'
                    top={0}
                    right={0}
                />
            </Container>
        </main>
    );
};

export default Checkout;