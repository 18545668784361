import {useTranslation} from "react-i18next";
import ReactDOM from "react-dom";
import useInput from "../../hooks/use-input";
import {useContext, useState} from "react";
import {makeQuickOrder} from "../../api/Order";
import {isPhone} from "../../helpers/Validator";
import LoginContext from "../../store/login-context";
import {useNavigate} from "react-router-dom";

import Col from "../grid/Col";
import CartWidgetItem from "../../pages/CartPage/CartWidgetItem";
import Backdrop from "../blocks/Header/Backdrop";
import SolidButton from "../UI/Buttons/SolidButton";
import Input from "../UI/Form/Input";
import CrossIcon from "../../assets/icons/CrossIcon";
import Spinner from "../loading/Spinner";

import './Modal.css';

const OneClickBuyModal = (props) => {
    const { t } = useTranslation();
    const loginCtx = useContext(LoginContext);
    const navigate = useNavigate();
    const [inProcess, setInProcess] = useState(false);

    const {
        value: fln,
        isValid: enteredFLNIsValid,
        hasError: flnInputHasError,
        valueChangeHandler: flnChangedHandler,
        inputBlurHandler: flnBlurHandler
    } = useInput(value => value.trim() !== '');

    const {
        value: phone,
        isValid: enteredPhoneIsValid,
        hasError: phoneInputHasError,
        valueChangeHandler: phoneChangedHandler,
        inputBlurHandler: phoneBlurHandler
    } = useInput(value => isPhone(value));

    const formIsValid = enteredPhoneIsValid && enteredFLNIsValid;

    const makeOrderHandler = async () => {
        if (formIsValid) {
            setInProcess(true);
            const response = await makeQuickOrder(loginCtx, props.id, fln, phone, props.amount);
            setInProcess(false);
            if (response.error === 'ok') {
                props.onClose();
                navigate('/success');
            }
        }
    }

    return ReactDOM.createPortal(
        <Backdrop className='d-flex align-items-center justify-content-center'>
            <aside className='modal__body'>
                <div className='d-flex justify-content-end' onClick={props.onClose}>
                    <CrossIcon />
                </div>
                <h1 className='modal__title modal__center primary-text'>
                    {t('modals.oneClick.header')}
                </h1>
                <Col sm={12} md={6}>
                    <CartWidgetItem
                        id={props.id}
                        title={props.title}
                        price={props.price}
                        discount={props.discount}
                        img={props.img}
                        subcategory_id={props.subcategory_id}
                        count={props.amount}
                        nocontrols
                    />
                </Col>
                <Col sm={12} md={6} style={{marginBottom: '50px'}}>
                    <Input
                        type='text'
                        placeholder={t('modals.oneClick.FLNamePlaceholder')}
                        value={fln}
                        onChange={flnChangedHandler}
                        onBlur={flnBlurHandler}
                        isError={flnInputHasError}
                    />
                    <Input
                        type='tel'
                        placeholder={t('modals.oneClick.phoneNumberPlaceholder')}
                        value={phone}
                        onChange={phoneChangedHandler}
                        onBlur={phoneBlurHandler}
                        isError={phoneInputHasError}
                    />
                </Col>
                <div className='mt-2 d-flex align-items-center'>
                    <SolidButton primary text={t('modals.oneClick.buyButton')} onClick={makeOrderHandler} className='w-25' disabled={!formIsValid} />
                    {inProcess && <Spinner />}
                </div>
            </aside>
        </Backdrop>
    , document.body);
};

export default OneClickBuyModal;