import {apiAddress} from "./APILink";

const getBreadcrumbsPath = (dispatch, id) => {
    dispatch({type: 'SetLoadingBreadcrumbs'});
    fetch(apiAddress + 'api/scripts/Subcategory.php?id=' + id, {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadBreadcrumbs', payload: data});
        });
}

export {getBreadcrumbsPath};