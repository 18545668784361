import {Fragment, useContext} from "react";
import {priceProcessing} from "../../helpers/Processors";
import {Link} from "react-router-dom";
import CartContext from "../../store/cart-context";
import LoginContext from "../../store/login-context";
import {decreaseCartItemHandler, deleteCartItemHandler, increaseCartItemHandler} from "./CartHandlers";

import Col from "../../components/grid/Col";
import LinkButton from "../../components/UI/Buttons/LinkButton";

import './CartWidgetItem.css';
import CrossIcon from "../../assets/icons/CrossIcon";

const CartWidgetItem = (props) => {
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);

    const price = priceProcessing(props.price);
    let priceBlock = <span className='cart-widget-item__price primary-text'>{price}</span>;
    if (props.discount) {
        const discountPrice = priceProcessing(props.discount);
        priceBlock = (
            <Fragment>
                <span className='cart-widget-item__price secondary-text'>{discountPrice}</span>
                <span className='cart-widget-item__price old-price'>{price}</span>
            </Fragment>
        );
    }

    let controls = (
        <div className='d-flex align-items-center justify-content-between white-text mt-1'>
            <div className="cart-widget-item__count d-flex align-items-center mr-2">
                        <span
                            className="cart-widget-item__count-controls"
                            onClick={() => decreaseCartItemHandler(props.id, cartCtx, loginCtx)}
                        >-</span>
                <span className="cart-widget-item__count-value">{props.count}</span>
                <span
                    className="cart-widget-item__count-controls"
                    onClick={() => increaseCartItemHandler(props.id, cartCtx, loginCtx)}
                >+</span>
            </div>
            <LinkButton
                text={<CrossIcon />}
                onClick={() => deleteCartItemHandler(props.id, cartCtx, loginCtx)}
            />
        </div>
    );
    if (props.nocontrols) {
        controls = (
            <span className="cart-widget-item__count-value white-text">X{props.count}</span>
        );
    }
    if (props.nocount) {
        controls = null;
    }

    return (
        <article className='cart-widget-item d-flex align-items-center'>
            <Col sm={4} md={3}>
                <img src={props.img} alt='' className='w-100' />
            </Col>
            <Col sm={8} md={9}>
                <h1 className='cart-widget-item__title white-text mb-1'>
                    <Link to={`/shop/${props.subcategory_id}/${props.id}`}>{props.title}</Link>
                </h1>
                {priceBlock}
                {controls}
            </Col>
        </article>
    );
};

export default CartWidgetItem;