import {useReducer} from "react";
import {Link} from "react-router-dom";
import {categoryBrowserReducer, initialCategoryBrowserReducer} from "../../reducers/CategoryBrowserReducer";
import {searchProducts} from "../../api/ProductInfo";
import {useTranslation} from "react-i18next";

import Input from "../../components/UI/Form/Input";
import Container from "../../components/container/Container";
import CartWidgetItem from "../CartPage/CartWidgetItem";
import Col from "../../components/grid/Col";
import SearchIcon from "../../assets/icons/SearchIcon";

import './SearchProduct.css';

const SearchProduct = () => {
    const { t, i18n } = useTranslation();
    const [state, dispatch] = useReducer(categoryBrowserReducer, initialCategoryBrowserReducer);

    let timeout;
    const searchHandler = (e) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            searchProducts(dispatch, e.target.value);
        }, 500);
    };

    let foundProducts
    if (state.productsLoaded) {
        foundProducts = state.initialProducts.map((item) => {
            return (
                <Link
                    to={`/shop/${item.subcategory_id}/${item.product_id}`}
                    className='w-100 search-product__link'
                    key={item.product_id}
                >
                    <Col sm={12} md={4}>
                        <CartWidgetItem
                            id={item.product_id}
                            title={item['product_name_' + i18n.resolvedLanguage]}
                            price={item.price}
                            discount={item.discount}
                            img={item.main_image}
                            subcategory_id={item.subcategory_id}
                            nocontrols
                            nocount
                        />
                    </Col>
                </Link>
            );
        });
    }

    return (
        <section className='p-relative' role='search'>
            <Container>
                <form className='search-product__container d-flex align-items-center' onSubmit={(e) => e.preventDefault()}>
                    <SearchIcon />
                    <Input
                        className='search-product__input'
                        placeholder={t('categorySelectionPage.searchbarPlaceholder')}
                        onChange={searchHandler}
                        nomargin
                    />
                </form>
                {
                    state.productsLoaded && state.initialProducts.length > 0 && (
                        <div className='search-product__results'>
                            {foundProducts}
                        </div>
                    )
                }
            </Container>
        </section>
    );
};

export default SearchProduct;