import {apiAddress} from "./APILink";

const makeOrder = async (loginCtx, city, address, FLName, phone, comment, payment_type, isExpress, callback, code) => {
    let fetchAddress = apiAddress + 'api/scripts/Order.php';
    if (loginCtx.isLoggedIn()) {
        const session_id = loginCtx.getSessionId();
        fetchAddress = apiAddress + 'api/scripts/Order.php?session_id=' + session_id;
    }
    let codeId = null;
    if (code !== null) codeId = code.id;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            city, address, FLName, phone, comment, payment_type, isExpress, callback, localCart: localStorage.getItem('cart'), codeId
        }),
    };

    const response = await fetch(fetchAddress, requestOptions);
    return await response.json();
};

const makeQuickOrder = async (loginCtx, id, fln, phone, count) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id, fln, phone, count
        }),
    };

    let address = 'api/scripts/Order.php?quickOrder';
    if (loginCtx.isLoggedIn()) {
        address += '&session_id=' + loginCtx.getSessionId();
    }
    const response = await fetch(apiAddress + address, requestOptions);
    return await response.json();
};

const getOrders = (loginCtx, dispatch) => {
    if (loginCtx.isLoggedIn()) {
        dispatch({type: 'SetLoadingOrders'});
        const sessionId = loginCtx.getSessionId();
        fetch(apiAddress + 'api/scripts/Order.php?orders&session_id=' + sessionId, {method: 'get'})
            .then(response => response.json())
            .then(data => {
                dispatch({type: 'LoadOrders', payload: data});
            });
    }
}

export {makeOrder, makeQuickOrder, getOrders};