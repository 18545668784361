const initialBreadcrumbsReducer = {
    breadcrumbs: {
        category_ro: '',
        category_ru: '',
        subcategory_ro: '',
        subcategory_ru: '',
        subcategory_id: null
    },
    breadcrumbsLoaded: false
};

const breadcrumbsReducer = (state, action) => {
    switch (action.type) {
        case 'SetLoadingBreadcrumbs':
            return {
                ...state,
                breadcrumbsLoaded: false
            }
        case 'LoadBreadcrumbs':
            return {
                ...state,
                breadcrumbs: {...action.payload},
                breadcrumbsLoaded: true
            }

        default:
            console.log('Something went wrong!');
            break;
    }
};

export {initialBreadcrumbsReducer, breadcrumbsReducer};