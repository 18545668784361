import SuccessIcon from "../../assets/icons/SuccessIcon";

import './ProductFeature.css';

const ProductFeature = (props) => {
    return (
        <div className='product-feature d-flex align-items-center'>
            <div className='col-1'>
                <SuccessIcon />
            </div>
            <h5 className='col-11 product-feature__title white-text'>{props.text}</h5>
        </div>
    );
};

export default ProductFeature;