const TelegramLogo = () => {
    return (
        <a href="https://t.me/+rE0pwMSnGAw1NGMy">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
            <path d="M18 0.0305176C8.05959 0.0305176 0 8.09011 0 18.0305C0 27.9709 8.05959 36.0305 18 36.0305C27.9404 36.0305 36 27.9709 36 18.0305C36 8.09011 27.9404 0.0305176 18 0.0305176ZM26.8457 12.3697L23.8922 26.2848C23.6902 27.2766 23.0914 27.5081 22.2612 27.0526L17.7612 23.7244L15.6012 25.8256C15.3478 26.0717 15.1494 26.2775 14.7012 26.2775C14.1171 26.2775 14.2163 26.0607 14.018 25.5024L12.4861 20.4697L8.03388 19.0848C7.07143 18.7909 7.06408 18.1297 8.25061 17.6558L25.5894 10.9628C26.3829 10.6064 27.1469 11.1538 26.8457 12.3697Z" fill="#CA9D2A"/>
        </svg>
        </a>
    );
};

export default TelegramLogo;