const initialCEOReducer = {
    CEOTextRU: '',
    CEOTextRO: '',
    CEOLoaded: false
};

const CEOReducer = (state, action) => {
    switch (action.type) {
        case 'SetLoadingCEO':
            return {
                ...state,
                CEOLoaded: false
            }
        case 'LoadCEO':
            return {
                ...state,
                CEOTextRU: action.payload.text_ru,
                CEOTextRO: action.payload.text_ro,
                CEOLoaded: true
            }

        default:
            console.log('Something went wrong!');
            break;
    }
};

export {CEOReducer, initialCEOReducer};