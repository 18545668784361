import {useTranslation} from "react-i18next";

import CartIcon from "../../../assets/icons/CartIcon";

const EmptyCart = () => {
    const { t } = useTranslation();
    return (
        <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-center'>
                <p><CartIcon width={60} height={40} /></p>
                <h2 className='gray-text'>{t('cart.emptyCartMessage')}</h2>
            </div>
        </div>
    );
};

export default EmptyCart;