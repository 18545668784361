import {apiAddress} from "./APILink";

const getProductImages = async (dispatch, product_id) => {
    dispatch({type: 'SetLoadingProductImages'});
    const response = await fetch(apiAddress + 'api/scripts/ProductImages.php?product_id=' + product_id, {method: 'get'})
    return await response.json();
};

const getProductInfo = async (dispatch, product_id) => {
    dispatch({type: 'SetLoadingProductInfo'});
    const response = await fetch(apiAddress + 'api/scripts/Products.php?product_id=' + product_id, {method: 'get'})
    return response.json();
};

const getProductInfoAsync = async (product_id) => {
    let response = await fetch(apiAddress + 'api/scripts/Products.php?product_id=' + product_id, {method: 'get'});
    return await response.json();
};

const getProducts = async (dispatch, subcategoryId) => {
    dispatch({type: 'SetLoadingProducts'});
    let response = await fetch(apiAddress + 'api/scripts/Products.php?subcategory=' + subcategoryId, {method: 'get'})
    return await response.json();
}

const getProductsByBrand = async (dispatch, brand) => {
    dispatch({type: 'SetLoadingProducts'});
    let response = await fetch(apiAddress + 'api/scripts/Products.php?brand=' + brand, {method: 'get'})
    return await response.json();
}

const searchProducts = (dispatch, searchText) => {
    dispatch({type: 'SetLoadingProducts'});
    fetch(apiAddress + 'api/scripts/Products.php?search=' + searchText, {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadProducts', payload: data});
        });
}

export {getProductImages, getProductInfo, getProductInfoAsync, getProducts, getProductsByBrand, searchProducts};