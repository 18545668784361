import './Grid.css';

const Col = (props) => {
    let classes = 'col';

    if (props.className) classes += ' ' + props.className;
    if (props.col) classes += ' col-' + props.col;
    if (props.sm) classes += ' col-sm-' + props.sm;
    if (props.md) classes += ' col-md-' + props.md;
    if (props.lg) classes += ' col-lg-' + props.lg;
    if (props.xl) classes += ' col-xl-' + props.xl;

    return (
        <div className={classes} style={props.style}>
            {props.children}
        </div>
    );
}

export default Col;