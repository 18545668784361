import {useEffect, useReducer} from "react";
import {initialMainPageReducer, mainPageReducer} from "../../reducers/MainPageReducer";
import {
    getDiscountProductsCarousel,
    getNewProductsCarousel,
    getPopularProductsCarousel,
    getPromoCarousel
} from "../../api/Carousels";
import {useTranslation} from "react-i18next";

import PromoCarousel from "../../components/carousels/PromoCarousel";
import ProductCarousel from "../../components/carousels/ProductCarousel";
import Line from "../../components/UI/Line";
import Container from "../../components/container/Container";
import TopBrands from "../../components/blocks/TopBrands/TopBrands";
import FeaturesBlock from "./FeaturesBlock";
import CEO from "../../components/blocks/CEO/CEO";
import Instagram from "../../components/blocks/Instagram/Instagram";
import Ball from "../../components/Ball";
import ComponentLoading from "../../components/loading/ComponentLoading";

import ScrollToTopButton from "../../helpers/ScrollToTopButton";

import './MainPage.css';

const MainPage = () => {
    const [state, dispatch] = useReducer(mainPageReducer, initialMainPageReducer);
    const { t } = useTranslation();

    useEffect(() => {
        getPromoCarousel(dispatch);
        getNewProductsCarousel(dispatch);
        getPopularProductsCarousel(dispatch);
        getDiscountProductsCarousel(dispatch);
    }, []);

    let promoCarousel = <ComponentLoading height={'60vh'} />;
    if (state.promoLoaded) promoCarousel = <PromoCarousel carouselInfo={state.promoCarouselInfo} />;

    let newCarousel = <ComponentLoading height={'60vh'} />;
    if (state.newLoaded) newCarousel = (
        <ProductCarousel
            title={t('carousels.newCarouselHeader')}
            carouselInfo={state.newCarouselInfo}
        />
    );

    let popularCarousel = <ComponentLoading height={'60vh'} />;
    if (state.popularLoaded) popularCarousel = (
        <div className='margin'>
            <ProductCarousel
                title={t('carousels.popularCarouselHeader')}
                carouselInfo={state.popularCarouselInfo}
            />
        </div>
    );

    let discountCarousel = <ComponentLoading height={'60vh'} />;
    if (state.discountLoaded) discountCarousel = (
        <div className='margin'>
            <ProductCarousel
                title={t('carousels.discountCarouselHeader')}
                carouselInfo={state.discountCarouselInfo}
            />
        </div>
    );

    return (
        <main className='p-relative'>
            <section className='slider'>
                <Container>
                    {promoCarousel}
                    <Line mt='40px' />
                    <Ball
                        background='#E4B948'
                        top='0'
                        right='0'
                    />
                    <Ball
                        background='#FE7A00'
                        top='400px'
                        left='0'
                    />
                </Container>
            </section>
            <section className='mainPage__carousels'>
                <Container>
                    {newCarousel}
                    {popularCarousel}
                    <FeaturesBlock />
                    {discountCarousel}
                    <Ball
                        background='#E49348'
                        bottom='-100px'
                        left='0'
                    />
                    <Ball
                        background='#FE7A00'
                        bottom='250px'
                        right='0'
                    />
                </Container>
            </section>
            <TopBrands />
            <CEO />
            <Instagram />
            <ScrollToTopButton />
        </main>
    );
};

export default MainPage;