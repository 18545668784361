import SidebarContainer from "../../container/SidebarContainer";
import Line from "../Line";

import './SiderbarHeader.css';
import CrossIcon from "../../../assets/icons/CrossIcon";

const SidebarHeader = (props) => {
    let classes = 'sidebar-header__title';

    if (props.primary) classes += ' primary-text';
    else classes += ' white-text'
    if (props.className) classes += ' ' + props.className;

    let content = <h1 className={classes}>{ props.text }</h1>;
    if (props.onClick) {
        content = (
            <div className='d-flex justify-content-between'>
                <span className='sidebar-header__close' onClick={props.onClick}><CrossIcon /></span>
                <h1 className={classes}>{ props.text }</h1>
                <div />
            </div>
        );
    }

    return (
        <div className='sidebar-header'>
            <SidebarContainer>
                {content}
            </SidebarContainer>
            <Line dark />
        </div>
    );
};

export default SidebarHeader;