import './Select.css';

const Select = (props) => {
    let selectClasses = '';
    if (props.fullWidth) selectClasses = 'w-100';

    let selectContent = [];
    if (props.count) {
        for (let i = 1; i <= 10; i++) {
            selectContent.push(<option value={i} key={i}>{i}</option>);
        }
    } else {
        selectContent = props.options.map(item => {
             return <option value={item.value} key={item.value}>{item.text}</option>
        });
    }

    const onChangeHandler = (e) => {
        props.onChange(e.target.value);
    }

    return (
        <div className='select-container d-flex align-items-center'>
            <select id={props.id} title={props.title} onChange={onChangeHandler} className={selectClasses} value={props.value}>
                {selectContent}
            </select>
        </div>
    );
};

export default Select;