import {apiAddress} from "./APILink";

const getCodeById = async (id, loginCtx) => {
    let sessionId = '';
    if (loginCtx.isLoggedIn()) sessionId = '&session_id=' + loginCtx.getSessionId();
    const response = await fetch(apiAddress + 'api/admin/scripts/ajax/Codes.php?get&codeId=' + id + sessionId, {method: 'get'});
    return response.json();
};
const getCodeByName = async (name, loginCtx) => {
    let sessionId = '';
    if (loginCtx.isLoggedIn()) sessionId = '&session_id=' + loginCtx.getSessionId();
    const response = await fetch(apiAddress + 'api/admin/scripts/ajax/Codes.php?get&codeName=' + name + sessionId, {method: 'get'});
    return response.json();
};
const sendCodeToEmail = async (email) => {
    const response = await fetch(apiAddress + 'api/admin/scripts/ajax/Codes.php?email=' + email, {method: 'get'});
    return response.json();
};

export {getCodeById, getCodeByName, sendCodeToEmail};