import {Fragment} from "react";
import {Link} from "react-router-dom";

import './HollowButton.css';

const HollowButton = (props) => {
    let classes = 'hollow-button fw-light';
    if (props.primary) classes += ' primary-button';
    if (props.secondary) classes += ' secondary-button';
    else {
        classes += ' primary-button';
    }
    if (props.className) classes += ' ' + props.className;

    let content = (<button className={classes} onClick={props.onClick}>{props.text}</button>);
    if (props.to) content = (<Link to={props.to} className={classes}>{props.text}</Link>);

    return (
        <Fragment>
            {content}
        </Fragment>
    );
};

export default HollowButton;