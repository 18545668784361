import './Radio.css';

const Radio = (props) => {
    return (
        <label className="radio__container gray-text">
            <input type="radio" name={props.name} checked={props.checked} onChange={props.onChange} />
            { props.text }
        </label>
    );
}

export default Radio;