import SearchProduct from "./SearchProduct";
import CategorySelection from "./CategorySelection";
import TopBrands from "../../components/blocks/TopBrands/TopBrands";
import CEO from "../../components/blocks/CEO/CEO";
import Instagram from "../../components/blocks/Instagram/Instagram";

import './CategorySelectPage.css';

const CategorySelectPage = () => {
    return (
      <main>
          <SearchProduct />
          <CategorySelection />
          <TopBrands />
          <CEO />
          <Instagram />
      </main>
    );
};

export default CategorySelectPage;