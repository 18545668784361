import './SolidButton.css';

const SolidButton = (props) => {
    let classes = 'solid-button fw-light';

    if (props.primary) classes += ' primary-button';
    if (props.secondary) classes += ' secondary-button';
    if (props.className) classes += ' ' + props.className;

    return (
        <button onClick={props.onClick} className={classes} disabled={props.disabled}>{props.text}</button>
    );
};

export default SolidButton;